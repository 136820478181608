import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { ZoomService } from '../../doc-process-common/services/zoom.service';
import { DocumentService } from '../../doc-process-common/services/document.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { InstanceService } from '../services/instance.service';
import { Store } from '@ngxs/store';
import { TaskDataEntryState } from '../state-management/states';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { InstanceDefinition } from '../interfaces/fields';

@Component({
  selector: 'con-pdf-viewer-wrapper',
  template: `
    <div class="card" style="height: 86vh">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink>
            <small>Inline</small>
          </a>
          <ng-template ngbNavContent>
            <div>
              <pdf-viewer
                [src]="documentSafeResourceUrl?.changingThisBreaksApplicationSecurity"
                [render-text]="true"
                [render-text-mode]="2"
                [show-all]="true"
                [original-size]="false"
                [autoresize]="false"
                [fit-to-page]="false"
                [zoom]="(zoomService.zoomMagnification | async) / 100"
                (click)="onClickEvent($event)"
                (after-load-complete)="scrollTo()"
                style="height: 85vh; display: block"
              ></pdf-viewer>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>
            <small>Embedded</small>
          </a>
          <ng-template ngbNavContent>
            <div *ngIf="displayDocument">
              <object style="width:100%; min-height: 85vh;" [data]="documentSafeResourceUrl" type="application/pdf">
                <embed #pdfElement name="plugin" [src]="documentSafeResourceUrl" type="application/pdf" />
              </object>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  `,
})
export class PdfViewerWrapperComponent implements OnInit, OnChanges {
  @Input() instanceDocument: any;
  displayDocument = true;
  public pdfPage: number;
  public documentSafeResourceUrl: any = null;
  public documentSafeResourceUrlSubscription: Subscription;

  constructor(public zoomService: ZoomService, private pdfService: DocumentService, private instanceService: InstanceService, private store: Store) {}

  @ViewChild(PdfViewerComponent) public pdfComponent: PdfViewerComponent;

  ngOnInit(): void {
    this.documentSafeResourceUrl = this.getPdfUrl();
  }

  ngOnChanges(changes: SimpleChanges) {}

  getPdfUrl(): SafeResourceUrl {
    const instanceDefinition = {
      InstanceDocument: {
        doc_content: this.instanceDocument?.doc_content,
      },
    } as InstanceDefinition;
    if (!instanceDefinition.InstanceDocument.doc_content) {
      return of('');
    }
    return this.pdfService.getDocument(instanceDefinition);
  }

  public scrollTo() {
    this.pdfPage = this.store.selectSnapshot(TaskDataEntryState.selectTaskInstanceById(this.instanceService.getSelectedInstanceId())).show_page;
    setTimeout(() => {
      const page = this.pdfComponent.pdfViewerContainer.nativeElement.getElementsByClassName('page').item(this.pdfPage - 1);
      page.scrollIntoView();
    }, 100);
  }

  onClickEvent($event: MouseEvent) {
    //implement for factsheet
  }
}
