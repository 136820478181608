import { Component, Input, OnInit } from '@angular/core';
import { DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { Observable } from 'rxjs';
import { TaskDataEntryState } from '../state-management/states';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { InstanceService } from '../services/instance.service';
import { SetComponentData } from '../state-management/actions';
import { ComponentService } from '../services/component.service';
import { DynamicDataEntryAbstractInput } from './dynamic-data-entry-abstract-input.component';
import { Hotkeys } from '../services/hotkeys.service';

@Component({
  selector: 'con-dynamic-data-entry-text-input',
  template: `
    <con-dynamic-confirmation-icon *ngIf="isConfirmable" [isConfirmed]="this.isConfirmed"></con-dynamic-confirmation-icon>
    <div class="form-group">
      <input type="text" class="form-control" class="form-control smaller-text-form w-100" [ngModel]="componentData.value" (ngModelChange)="updateValue($event)" name="text" />
    </div>
  `,
})
export class DynamicDataEntryTextinputInputComponent extends DynamicDataEntryAbstractInput {
  @Input() component: DynamicDataEntryComponentInterface;

  constructor(store: Store, instanceService: InstanceService, componentService: ComponentService, hotkeys: Hotkeys) {
    super(store, instanceService, componentService, hotkeys);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  updateValue($event: any) {
    this.componentValueToStore($event);
  }
}
