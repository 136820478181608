export const environment = {
  production: false,
  stage: false,
  oldstage: false,
  dev: true,
  company_static_tier: 1,
  company_calendar_tier: 2,
  company_marketcap_tier: 3,
  company_shareholders_tier: 34,
  company_report_tier: 67,
  institution_calendar_tier: 1,
  api_host: 'https://ml.test.be.infrontdata.com',
  domain: 'ml.test.be.infrontdata.com'
};
