import { Component, Input, OnInit } from '@angular/core';
import { ComponentConfirmationEnum, DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { Store } from '@ngxs/store';
import { InstanceService } from '../services/instance.service';
import { ComponentService } from '../services/component.service';
import { TaskDataEntryState } from '../state-management/states';
import { Hotkeys } from '../services/hotkeys.service';
import { InstanceDataValue } from '../interfaces/instance-data';
import {SetComponentData} from '../state-management/actions';

@Component({
  selector: 'con-dynamic-data-entry-shareclasses',
  template: `
    <div class="container-fluid mt-0 mb-3 share-class-area">
      <div class="row">
        <div class="col-12 share-class-container">
          <div [ngClass]="i === 0 ? 'share-class-field col-6 m-0 text-center p-0' : ' share-class-field col-3 p-0 m-0 text-center'" *ngFor="let shareClass of componentData; let i = index">
            <button (click)="removeShareClass(i)" class="btn btn-sm btn-danger mb-1" [disabled]="i === 0">
              <con-icon-by-name iconName="minus"></con-icon-by-name>
            </button>
            <ng-container *ngIf="componentData.length">
              <div class="p-1 d-flex full-width justify-content-between col-12 p-0" *ngFor="let component of component.components" [ngClass]="{ selected: selectedClasses.includes(component.title) }">
                <label *ngIf="i === 0" class="my-1 d-none d-sm-block"
                  ><span class="badge badge-light">{{ component.title }}</span></label
                >
                <con-dynamic-data-entry-shareclasses-input
                  class="d-flex align-items-center justify-content-end"
                  [component]="component"
                  [isSelected]="selectedClasses.includes(component.title)"
                  [isInstanceSelected]="instanceService.isInstanceSelected"
                  [inputData]="componentData[i][component.title]"
                  (confirmationEvent)="onConfirm(componentData[i][component.title])"
                  (updateEvent)="onUpdateValue($event, componentData[i][component.title])"
                ></con-dynamic-data-entry-shareclasses-input>
              </div>
            </ng-container>
          </div>
          <div class="col-1 d-flex m-0 p-0 align-items-center justify-content-center">
            <button (click)="addNewShareClass()" class="btn btn-sm btn-primary">
              <con-icon-by-name iconName="plus"></con-icon-by-name>
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .share-class-area {
        overflow: auto;
      }

      .share-class-container {
        display: inline-flex;
        min-height: 170px;
        padding: 0;
      }

      button[disabled] {
        opacity: 0;
      }

      .selected {
        background-color: rgb(209, 236, 241);
      }
    `,
  ],
})
export class DynamicShareClassesComponent {
  @Input() component: DynamicDataEntryComponentInterface;
  public componentData: any;
  public selectedClasses: string[];

  constructor(private store: Store, public instanceService: InstanceService, private componentService: ComponentService, private hotkeys: Hotkeys) {}

  ngOnInit() {
    this.componentData = this.store.selectSnapshot(TaskDataEntryState.selectComponentData(this.instanceService.getInstanceId(), this.component));
    const selectedComponents = this.store.selectSnapshot(TaskDataEntryState.selectTaskInstances).current_fields;
    this.selectedClasses = selectedComponents.map((item) => item.split(':')[1]);
  }

  removeShareClass(i: number) {
    this.componentData.splice(i, 1);
  }

  addNewShareClass() {
    const newShareClass = { ...this.componentData[0] };
    Object.keys(newShareClass).forEach((v) => (newShareClass[v] = this.createNewInstanceDataValue()));
    this.componentData.push(newShareClass);
  }

  private createNewInstanceDataValue(): InstanceDataValue {
    return {
      value: null,
      ml_conf: null,
      altered: null,
      confirmed: null,
    } as InstanceDataValue;
  }

  onUpdateValue(value: any, componentData) {
    componentData.value = value;
    componentData.ml_conf = 'High';
    componentData.confirmed = ComponentConfirmationEnum.ALTERED;
    componentData.altered = true;
    this.store.dispatch(new SetComponentData(this.instanceService.getInstanceId(), this.component, this.componentService.componentOutcomingDataAdapter(value, this.componentData)))

  }

  onConfirm(componentData) {
    componentData.ml_conf = 'High';
    componentData.confirmed = ComponentConfirmationEnum.CHECKED;
  }
}
