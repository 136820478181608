import { Component, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { UtilService } from '../../doc-process-common/services/util.service';
import { Store } from '@ngxs/store';
import { FetchTaskDataEntryState } from '../state-management/actions';
import { InstanceDataTopicClassification } from '../interfaces/instance-data';
import { TopicClassificationField } from '../interfaces/fields';

@Component({
  selector: 'con-multiclass-taxonomy',
  template: `
    <button (click)="restoreData()" style="float:right" class="m-1 btn-warning btn-md cursor-pointer lang-button d-flex align-items-center flex-nowrap">
      Reload data
      <con-icon-by-name class="ml-2" iconName="refresh"></con-icon-by-name>
    </button>
    <ng-container *ngFor="let field of instanceData.taxonomy_data.classes; let fieldIndex = index">
      <ng-container>
        <div class="{{ getTaxonomyFieldLeftPaddingCssClass(field) }}" class="border-bottom">
          <div class="container-fluid p-0 m-0">
            <button
              type="button"
              [ngClass]="{ active: fieldIndex === highlightedFieldIndex }"
              [class]="getButtonClass(field)"
              class=" text-start btn-block btn py-0 pl-1"
              [disabled]="!isSelectable(field) && field.value === false"
              style="{{ getDepthOfClass(field) >= 2 ? 'font-weight:400;' : 'font-weight:600;' }}"
              (click)="onTaxonomyFieldClick(fieldIndex)"
            >
              <span class="pull-left" style="text-align: left">
                {{ field?.title }}
              </span>
              <con-icon-by-name [iconName]="'check-square'" [hidden]="field?.value === false" class="float-right" style="color: white;"></con-icon-by-name>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `,
  styles: [
    `
      .active {
        outline: 3px rgba(126, 88, 194, 0.6) solid;
      }
    `,
  ],
})
export class MulticlassTaxonomyComponent implements OnChanges {
  @Input() instanceData: InstanceDataTopicClassification;
  @Input() highlightedFieldIndex: number;
  get highlightedField(): TopicClassificationField {
    return this.getHighlightedField();
  }

  stopPropagation($event) {
    $event.stopPropagation();
  }

  constructor(private utilService: UtilService, private store: Store) {}

  public ngOnChanges() {
    this.highlightedFieldIndex = this.instanceData.taxonomy_data.classes.length;

    this.focusFirstAvailableField();
  }

  public getTaxonomyFieldLeftPaddingCssClass(field: TopicClassificationField): string {
    let cssClass: string;

    const levelInClassTree: number = this.getDepthOfClass(field);
    const leftPadding = levelInClassTree === 0 ? 0 : levelInClassTree + 3;
    cssClass = `pl-${leftPadding}`;

    return cssClass;
  }

  private getHighlightedField(): TopicClassificationField {
    return this.getField(this.highlightedFieldIndex);
  }

  private getField(fieldIndex: number): TopicClassificationField {
    return this.instanceData.taxonomy_data.classes[fieldIndex];
  }

  public onTaxonomyFieldClick(fieldIndex: number): void {
    this.toggleMarkForField(fieldIndex);
    this.updateHighlightedFieldIndex(fieldIndex);
  }

  // warning: recursive
  public getDepthOfClass(field: TopicClassificationField): number {
    if (field.parent_field === null) {
      return 0;
    }

    const parentField = this.getParentField(field);
    return 1 + this.getDepthOfClass(parentField);
  }

  public getButtonClass(field: TopicClassificationField): string {
    let buttonColorClass: string;
    if (field.value === true || (!this.isSelectable(field) && !this.isMultiClass())) {
      buttonColorClass = 'btn-info';
    } else if (field.suggestion === true) {
      buttonColorClass = 'btn-warning';
    } else {
      buttonColorClass = 'btn-default';
    }
    return buttonColorClass;
  }

  public isSelectable(field: TopicClassificationField): boolean {
    if (this.hasMarkedParent(field) || this.hasMarkedChild(field)) {
      return false;
    }
    if (this.isMultiClass() && this.markedFieldCount() >= 1) {
      return false;
    } else {
      return true;
    }
  }

  public hasMarkedChild(field: TopicClassificationField): boolean {
    let isAChildSelected = false;
    this.getChildrenOfField(field).forEach((subField) => {
      isAChildSelected = isAChildSelected || subField?.value || this.hasMarkedChild(subField);
    });
    return isAChildSelected;
  }

  public hasMarkedParent(field: TopicClassificationField): boolean {
    if (!field?.parent_field) {
      return false;
    }
    const parentField = this.getParentField(field);
    const isAParentSelected = Boolean(parentField?.value || this.hasMarkedParent(parentField));
    return isAParentSelected;
  }

  private getChildrenOfField(field: TopicClassificationField): Array<TopicClassificationField> {
    return this.instanceData.taxonomy_data.classes.filter((eachField) => eachField.parent_field === field.field);
  }

  private getParentField(field: TopicClassificationField): TopicClassificationField {
    if (!field || !field?.parent_field) {
      return null;
    }
    return this.instanceData.taxonomy_data.classes.find((eachField) => eachField?.field === field.parent_field);
  }

  private toggleMarkForField(fieldIndex: number): void {
    const field = this.getField(fieldIndex);
    field.value = !field.value;
  }

  private updateHighlightedFieldIndex(fieldIndex: number): void {
    this.highlightedFieldIndex = fieldIndex;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyboardEvent(ev: KeyboardEvent) {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      ev.stopPropagation();
      this.highlightedField.value = !this.highlightedField.value;
    } else if (ev.shiftKey && ev.key === 'Tab') {
      ev.preventDefault();
      ev.stopPropagation();
      this.highlightNextField({ reverse: true });
    } else if (ev.key === 'Tab') {
      ev.preventDefault();
      ev.stopPropagation();
      this.highlightNextField();
    } else if (ev.key === 'Alt') {
      ev.preventDefault();
      ev.stopPropagation();
      do {
        this.highlightNextField();
      } while (this.getDepthOfClass(this.highlightedField) != 1);
    }
  }

  private highlightNextField(order?: { reverse: boolean }): void {
    do {
      this.highlightedFieldIndex = this.getNextFieldIndex(order);
    } while (this.isSelectable(this.getHighlightedField()) === false && this.highlightedField.value === false);
  }

  private getNextFieldIndex(order?: { reverse: boolean }): number {
    return this.utilService.modulo(this.highlightedFieldIndex + (order?.reverse ? -1 : 1), this.instanceData.taxonomy_data.classes.length);
  }

  public focusFirstAvailableField(): void {
    this.highlightNextField({ reverse: true });
    this.highlightNextField();
  }

  private markedFieldCount(): number {
    return this.instanceData.taxonomy_data.classes.filter((field: TopicClassificationField) => field.value === true).length;
  }

  private isMultiClass(): boolean {
    return this.instanceData.taxonomy_data?.classification_type === 'multiclass';
  }

  restoreData() {
    this.store.dispatch(FetchTaskDataEntryState);
  }
}
