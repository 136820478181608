import { Component, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { TaxonomyFieldListService } from '../services/taxonomy-field-list.service';
import { ReportStatement } from '../models/report-statement';
import { UtilService } from '../../doc-process-common/services/util.service';
import { TaxonomyModule } from '../../doc-process-common/models/taxonomy-module';
import { InstanceService } from '../services/instance.service';

@Component({
  selector: 'con-taxonomy-fields-list',
  providers: [TaxonomyFieldListService],
  template: `
    <div *ngFor="let module of statementModules" style="font-size: 80%">
      <con-doc-process-taxonomy-field [moduleData]="module" [treeLevel]="0" [ngClass]="{ disabled: reportStatement.is_unavailable }"></con-doc-process-taxonomy-field>
    </div>
  `,
})
export class TaxonomyFieldsListComponent implements OnInit {
  @Input() reportStatement: ReportStatement;
  private nOfModules = 0;
  public statementModules: Array<TaxonomyModule>;

  constructor(private taxonomyFieldListService: TaxonomyFieldListService, private instanceService: InstanceService) {}

  ngOnInit(): void {
    function findMaxModulePosition(module: TaxonomyModule): number {
      let maxPositionIndex: number = module?.position ?? 0;

      for (const childModule of module?.children ?? []) {
        maxPositionIndex = Math.max(maxPositionIndex, findMaxModulePosition(childModule));
      }

      return maxPositionIndex;
    }

    this.nOfModules = findMaxModulePosition({ children: this.reportStatement.modules } as TaxonomyModule);

    this.statementModules = ReportStatement.sortedModules(this.reportStatement);
  }

  @HostListener('window:keydown', ['$event'])
  onKeyboardEvent(ev: KeyboardEvent) {
    // noinspection RedundantConditionalExpressionJS
    const focusedModulePosition = this.taxonomyFieldListService.focusedTaxonomyModulePosition.getValue();
    const currModule: TaxonomyModule = this.findModuleByPosition(focusedModulePosition);
    if (currModule) {
      let keepItLoopin: boolean = currModule.is_highlighted ? true : false;
      let newModulePosition: number;
      do {
        if (ev.shiftKey && ev.key === 'Tab') {
          ev.preventDefault();
          ev.stopPropagation();

          newModulePosition = UtilService.modulo(focusedModulePosition - 2, this.nOfModules) + 1;
          this.taxonomyFieldListService.focusedTaxonomyModulePosition.next(newModulePosition);
        } else if (ev.key === 'Tab') {
          ev.preventDefault();
          ev.stopPropagation();

          const focusedModulePosition = this.taxonomyFieldListService.focusedTaxonomyModulePosition.getValue();
          newModulePosition = UtilService.modulo(focusedModulePosition, this.nOfModules) + 1;
          this.taxonomyFieldListService.focusedTaxonomyModulePosition.next(newModulePosition);
        } else {
          return;
        }

        const newModule = this.findModuleByPosition(newModulePosition);
        if (newModule.is_highlighted) {
          keepItLoopin = false;
        }
      } while (keepItLoopin);
    }
  }

  private findModuleByPosition(position: number): TaxonomyModule {
    for (const childModule of this.statementModules) {
      const moduleObj = childModule;

      const targetModule = TaxonomyModule.findModuleByPosition(moduleObj, position);
      if (targetModule) return targetModule;
    }
    return null;
  }
}
