import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ComponentConfirmationEnum, DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { Hotkeys } from '../services/hotkeys.service';
import { TaskDataEntryState } from '../state-management/states';
import { Store } from '@ngxs/store';
import { InstanceService } from '../services/instance.service';
import { ShareClassFactsheet } from '../interfaces/share-class';
import { Subscription } from 'rxjs';

@Component({
  selector: 'con-dynamic-factsheet-table',
  template: `
    <table class="table table-bordered table-hover" [ngClass]="{ selected: isSelected }">
      <thead>
        <tr>
          <ng-container *ngFor="let header of tableHeaders">
            <th *ngIf="header === 'Name'" class="w-auto">Name</th>
            <th *ngIf="!(header === 'Name')">
              <div>
                <span>{{ header }}</span>
              </div>
            </th>
          </ng-container>

          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let entry of this.componentData.value; let i = index">
          <td *ngFor="let component of component.components; let j = index" class="p-0 pb-1 p-1 ">
            <con-dynamic-factsheet-input [component]="component" [inputData]="componentData.value[i]" (updateEvent)="onUpdateValue($event, i)"></con-dynamic-factsheet-input>
          </td>
          <td class="py-0 px-1 align-middle p-0 buttons-cell">
            <button class="btn btn-primary smaller-button px-1 py-0 m-0" (click)="addNewRow()">
              <con-icon-by-name iconName="plus"></con-icon-by-name>
            </button>
            <button class="btn btn-danger smaller-button px-1 py-0 m-0" (click)="removeRow(i)">
              <con-icon-by-name iconName="minus"></con-icon-by-name>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="text-right"><strong>Total:</strong></td>
          <td class="text-left">{{ sumOfValueColumn }}</td>
        </tr>
      </tfoot>
    </table>
  `,
  styles: [
    `
      .smaller-button {
        padding: 0.1rem 0.4rem;
      }
      table.selected {
        background-color: rgb(209, 236, 241);
      }

      th {
        border: none;
        text-transform: capitalize;
      }

      td {
        text-align: center;
      }
      td:not(:first-child):not(:last-child) {
        width: 15%;
      }

      th.rotate {
        height: 80px;
        width: 30px;
        white-space: nowrap;
      }

      th.rotate > div {
        transform: translate(-4px, 3px) rotate(315deg);
        width: 30px;
      }

      th.rotate > div > span {
        padding: 5px 10px;
      }

      .buttons-cell {
        width: 60px;
        border-left: none;
      }

      tfoot {
        font-size: 0.8rem;
      }
    `,
  ],
})
export class DynamicFactsheetTableComponent implements OnInit, OnDestroy {
  @Input() component: DynamicDataEntryComponentInterface;
  public componentData: any;
  public tableHeaders: string[];
  public selectedComponents: string[];
  public isSelected: boolean;
  private subscription: Subscription;

  get sumOfValueColumn(): string {
    const allValues: Array<number> = this.componentData.value.map((a: ShareClassFactsheet) => Number(a.value) ?? 0);
    const sumOfAllValues: number = allValues.reduce((prevRowValue: number, curRowValue: number) => {
      return prevRowValue + curRowValue;
    }, 0);
    return sumOfAllValues.toFixed(2);
  }

  constructor(private hotkeys: Hotkeys, private store: Store, private instanceService: InstanceService) {}

  ngOnInit(): void {
    this.componentData = this.store.selectSnapshot(TaskDataEntryState.selectComponentData(this.instanceService.getInstanceId(), this.component));
    this.selectedComponents = this.store.selectSnapshot(TaskDataEntryState.selectTaskInstances).current_fields;
    this.tableHeaders = this.component.components.map((component) => component.title);
    this.isSelected = this.selectedComponents.includes(this.component.title);
    this.addEmptyRowIfLastRowIsNotEmpty();
    this.subscription = this.hotkeys.addShortcut({ keys: 'shift.c' }).subscribe(() => {
      this.putValueIntoNextEmptyCell(window.getSelection().toString());
    });
  }

  addNewRow() {
    const newRow = { ...this.componentData.value[0] };
    Object.keys(newRow).forEach((v) => (newRow[v] = null));
    this.componentData.value.push(newRow);
  }

  removeRow(i: number) {
    this.componentData.value.splice(i, 1);
    if (!this.componentData.value.length) {
      this.addNewRow();
    }
  }

  private lastRowHasValue(): boolean {
    return Object.values(this.componentData.value[this.componentData.value.length - 1]).some((value) => value != null);
  }

  private addEmptyRowIfLastRowIsNotEmpty() {
    if (this.lastRowHasValue()) {
      this.addNewRow();
    }
  }

  private getFirstEmptyFieldIndex() {}

  public putValueIntoNextEmptyCell(textCopied: string) {
    const lastEntryLine = this.componentData.value[this.componentData.value.length - 1];
    const lastEntryLineIndex = this.componentData.value.length - 1;
    const firstEmptyField = Object.entries(lastEntryLine).find((entry) => entry[1] === null);
    this.componentData.value[this.componentData.value.length - 1] = { ...this.componentData.value[0] };
    this.onUpdateValue({ value: textCopied, mapping: firstEmptyField[0] }, lastEntryLineIndex);
  }

  onUpdateValue($event: any, index) {
    this.componentData.value[index][$event.mapping] = $event.value;
    this.componentData.ml_conf = 'High';
    this.componentData.confirmed = ComponentConfirmationEnum.ALTERED;
    this.componentData.altered = true;
    this.addEmptyRowIfLastRowIsNotEmpty();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
