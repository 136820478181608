import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TaxonomySwitchService } from '../services/taxonomy-switch.service';
import { TaxonomyTypeEnum } from '../models/taxonomy-type-enum';
import { InstanceService } from '../services/instance.service';
import { DataEntryTaxonomyDetails } from '../models/data-entry-taxonomy-details';
import { TaxonomyModule } from '../../doc-process-common/models/taxonomy-module';
import { Task } from '../models/Task';
import { ToastrService } from 'ngx-toastr';
import { Select, Store } from '@ngxs/store';
import { TaskDataEntryState } from '../state-management/states';
import { DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { Document } from '../models/document';

@Component({
  selector: 'con-data-entry-instance',
  providers: [InstanceService, TaxonomySwitchService],
  styles: [
    `
      .sidebar-wrapper {
        height: 90vh;
        overflow-x: hidden;
        overflow-y: auto;
      }
    `,
  ],
  template: `
    <div class="col-10 px-1" [hidden]="hide" *ngIf="(isTaskDataLoaded$ | async) && (instanceDocument$ | async); else loader">
      <div class="row px-1 mx-0" *ngIf="instanceDocument$ | async as instanceDocument">
        <ng-container *ngIf="!hide">
          <doc-process-annotate-doc-view
            *ngIf="instanceDocument.doc_type === 'html'"
            [instanceDocument]="instanceDocument"
            [fundamentalsStatementService]="instanceService.activeFundamentalsStatementService | async"
            class="px-1"
            [ngClass]="'col-' + documentWidth"
          ></doc-process-annotate-doc-view>
          <con-pdf-viewer-wrapper class="pl-0 pr-1" *ngIf="instanceDocument.doc_type === 'pdf'" [instanceDocument]="instanceDocument" [ngClass]="'col-' + documentWidth"> </con-pdf-viewer-wrapper>
        </ng-container>
        <div class="pl-1 pr-2 sidebar-wrapper" [ngClass]="'col-' + sidebarWidth" *ngIf="instanceComponents$ | async">
          <dp-dynamic-data-entry-component-wrapper [component]="instanceComponents$ | async"></dp-dynamic-data-entry-component-wrapper>
        </div>
      </div>
    </div>

    <ng-template #loader>
      <div class="card col-10">
        <div class="card-body">
          <con-loader></con-loader>
        </div>
      </div>
    </ng-template>
  `,
})
export class DataEntryInstanceComponent implements OnInit {
  @Input() hide: boolean;
  @Input() instanceIndex: number;
  @Input() instanceId: number;

  @Select(TaskDataEntryState.selectTaskInstances) taskInstances$: Observable<Task>;
  @Select(TaskDataEntryState.selectIsTaskDataLoaded) isTaskDataLoaded$: Observable<boolean>;
  public instanceComponents$: Observable<DynamicDataEntryComponentInterface>;
  public instanceDocument$: Observable<Document>;
  public sidebarWidth = 4;
  public documentWidth: number;
  private subscribeUntil: Subject<boolean> = new Subject();

  get TaxonomyTypeEnum() {
    return TaxonomyTypeEnum;
  }

  constructor(public instanceService: InstanceService, private toastrService: ToastrService, private store: Store) {}

  ngOnInit(): void {
    this.instanceDocument$ = this.store.select(TaskDataEntryState.selectInstanceDocuments(this.instanceId));
    this.sidebarWidth = this.store.selectSnapshot(TaskDataEntryState.selectSidebarWidth);
    this.documentWidth = 12 - this.sidebarWidth;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.instanceService.setInstanceId(this.store.selectSnapshot(TaskDataEntryState.selectTaskInstanceByIndex(this.instanceIndex)).instance_id);
    this.instanceComponents$ = this.store.select(TaskDataEntryState.selectInstanceComponents(this.instanceId));
  }

  ngOnDestroy() {
    this.subscribeUntil.next(true);
  }

  private getInstanceData() {}

  private fillValuesIntoInstanceData(instanceData: DataEntryTaxonomyDetails, annotationsFromAllTaxonomies: any[]) {
    function findModuleInStatement(module: TaxonomyModule, moduleIdentifierToFind: string): any {
      if (module.unique_id === moduleIdentifierToFind) return module;
      else if (!module?.children?.length) return null;
      else {
        for (const subModule of module.children) {
          const resultOfSubModule = findModuleInStatement(subModule, moduleIdentifierToFind);
          if (resultOfSubModule) return resultOfSubModule;
        }
      }
      return null;
    }

    for (const annotation of annotationsFromAllTaxonomies) {
      const statementOfAnnotation =
        instanceData.income_statements.find((statement) => statement.id === annotation.fields[0].context.statementId) ||
        instanceData.balance_sheets.find((statement) => statement.id === annotation.fields[0].context.statementId) ||
        instanceData.cash_flow_statements.find((statement) => statement.id === annotation.fields[0].context.statementId) ||
        instanceData.snapshot_kpis.find((statement) => statement.id === annotation.fields[0].context.statementId) ||
        instanceData.period_kpis.find((statement) => statement.id === annotation.fields[0].context.statementId);

      if (statementOfAnnotation.quantity) statementOfAnnotation.quantity = annotation.fields[0].context.quantity;

      const module = findModuleInStatement({ children: statementOfAnnotation.modules, name: '' } as TaxonomyModule, annotation.fields[0].field);

      if (!module.value_rep && module.value_rep !== 0) module.value_rep = null;

      module.value_rep += annotation.fields[0].context.scalarValue * this.getMultiplierForAnnotation(statementOfAnnotation.quantity, annotation) * (annotation.fields[0].context.subtract ? -1 : 1);
    }

    return instanceData;
  }

  private getMultiplierForAnnotation(quantity: { multiplier: number }, annotation: any): number {
    if (quantity?.multiplier) return quantity.multiplier;
    else return annotation.fields[0].context.quantity.multiplier;
  }

  private displayTaxonomyNotFoundToastr() {
    this.toastrService.warning("Oops! Couldn't find the default taxonomy type for at least one instance. Displaying an available taxonomy type for those instances.", 'Taxonomy type not found', {
      timeOut: 10000,
    });
  }
}
