import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { InstanceService } from '../services/instance.service';
import { TaskDataEntryState } from '../state-management/states';

@Component({
  selector: 'con-company-iframe',
  styles: [],
  template: ` <iframe [src]="'/entity/company/' + companyId | safe" class="m-0 p-0 iframe" style="width: 100%; height: 75vh;"></iframe> `,
})
export class ConnectorIframeComponent implements OnInit {
  public companyId;

  constructor(private store: Store, private instanceService: InstanceService) {}

  ngOnInit(): void {
    this.companyId = this.store.selectSnapshot(TaskDataEntryState.selectCompanyId(this.instanceService.getInstanceId()));
  }
}
