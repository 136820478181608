import { Component, Input, OnInit } from '@angular/core';
import { LabelingHelperService } from '../services/labeling-helper.service';

@Component({
  selector: 'con-fundamentals-field-primary-value',
  template: `
    <ng-container *ngIf="data.annotationsSum !== null && data.annotationsSum !== undefined; else childrenModulesConsolidationTemplate">
      <!--                annotationsSum or selection-->

      <div class="text-right text-monospace {{ !!moduleData?.level ? 'font-weight-bold' : 'font-weight-normal' }}">
        <con-icon-by-name
          *ngIf="labelingHelperService.hasSanityCheck() && data.hasSanityError"
          [iconName]="'warning'"
          class="text-warning ml-4"
          ngbPopover="There is an inconsistency between the amounts (tagged values and quantities) of different annotations for this field."
          container="body"
          [openDelay]="300"
          [closeDelay]="500"
          triggers="mouseenter:mouseleave"
        >
          >
        </con-icon-by-name>
        <ng-container *ngIf="!labelingHelperService.hasIndividualQuantifiers()">
          {{ data.annotationsSum?.toFixed(2) }}
        </ng-container>
        <ng-container *ngIf="labelingHelperService.hasIndividualQuantifiers()">
          {{ valueWithQuantityString }}
        </ng-container>

        <ng-container *ngIf="labelingHelperService.hasIndividualQuantifiers() && !labelingHelperService.hasConsolidatedAnnotations()"> </ng-container>
        <ng-content></ng-content>
      </div>
    </ng-container>

    <ng-template #childrenModulesConsolidationTemplate>
      <ng-container *ngIf="labelingHelperService.isDeltaDisplayed$ | async">
        <ng-container *ngIf="{ sumOfChildModules: data.childrenConsolidation } as data">
          <div
            *ngIf="data.sumOfChildModules !== null && data.sumOfChildModules !== undefined && data.sumOfChildModules !== 0; else levelValue"
            class="text-right text-monospace font-italic text-secondary {{ !!moduleData?.level ? 'font-weight-bold' : 'font-weight-normal' }}"
          >
            {{ data.sumOfChildModules?.toFixed(2) }}
          </div>
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template #levelValue>
      <ng-container *ngIf="moduleData?.level && labelingHelperService.isDeltaDisplayed$ | async">
        <!--                  levelSum-->
        <div
          *ngIf="data.levelSum !== null && data.levelSum !== undefined"
          class="text-right text-monospace font-italic text-secondary {{ !!moduleData?.level ? 'font-weight-bold' : 'font-weight-normal' }}"
        >
          {{ data.levelSum?.toFixed(2) }}
        </div>
      </ng-container>
    </ng-template>
  `,
})
export class FundamentalsFieldPrimaryValueComponent implements OnInit {
  @Input() moduleData: any;
  @Input() data: any;
  public valueWithQuantityString: string;

  constructor(public labelingHelperService: LabelingHelperService) {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.labelingHelperService.hasIndividualQuantifiers() && this.labelingHelperService.hasConsolidatedAnnotations()) {
      this.valueWithQuantityString = this.getValueWithQuantity(this.data.annotationsSum);
    }

    if (this.labelingHelperService.hasIndividualQuantifiers() && !this.labelingHelperService.hasConsolidatedAnnotations()) {
      this.valueWithQuantityString = this.getValueWithQuantity(this.data.lastSelected$.getValue());
    }

    //sets value_rep in module to be propagated to backend
    if (this.data.annotationsSum || this.data.annotationsSum === 0) {
      this.moduleData.value_rep = this.labelingHelperService.calculateModuleValueRep(this.data.annotationsSum);
    } else this.moduleData.value_rep = null;
  }

  private getValueWithQuantity(num: number): string {
    if (!num) return;

    const valueWithQuantity = { value: num, multiplier: 1 };
    while (valueWithQuantity.value % 1000 === 0) {
      valueWithQuantity.multiplier *= 1000;
      valueWithQuantity.value /= 1000;
    }

    let valueWithQuantityString = valueWithQuantity.value?.toFixed(2) + ' ';

    switch (valueWithQuantity.multiplier) {
      case 1:
        valueWithQuantityString += '∅';
        break;
      case 1000:
        valueWithQuantityString += 'T';
        break;
      case 1000000:
        valueWithQuantityString += 'M';
        break;
      case 1000000000:
        valueWithQuantityString += 'B';
        break;
    }
    return valueWithQuantityString;
  }
}
