import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TaxonomyTypeEnum } from '../models/taxonomy-type-enum';
import { map } from 'rxjs/operators';
import { DocProcessService } from '../../doc-process-common/services/doc-process.service';
import { InstanceService } from './instance.service';

@Injectable({
  providedIn: null,
})
export class TaxonomySwitchService {
  // TODO remove me
  private activeTaxonomyType: BehaviorSubject<TaxonomyTypeEnum> = new BehaviorSubject<TaxonomyTypeEnum>(TaxonomyTypeEnum.WholeReportDocument);

  constructor(private docProcessService: DocProcessService, private instanceService: InstanceService) {}

  public isTaxonomyTypeActive(taxonomyType: TaxonomyTypeEnum): Observable<boolean> {
    return this.activeTaxonomyType.pipe(
      map((activeTaxonomyType: TaxonomyTypeEnum) => {
        return activeTaxonomyType === taxonomyType;
      })
    );
  }

  public updateActiveTaxonomyType(taxonomyType: TaxonomyTypeEnum): void {
    this.activeTaxonomyType.next(taxonomyType);
  }

  public displayDefaultTaxonomyType() {
    // let defaultTaxonomy = this.docProcessService.fundamentalsDefaultTaxonomyType.getValue() as TaxonomyTypeEnum
    // if (!!this.instanceService.getReportStatementsFrom(defaultTaxonomy)) {
    //   this.activeTaxonomyType.next(defaultTaxonomy)
    //   return true
    // }
    // else {
    //   this.activeTaxonomyType.next(TaxonomyTypeEnum.WholeReportDocument)
    //   return false
    // }
  }
}
