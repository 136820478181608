import { Injectable } from '@angular/core';
import { ComponentService } from './component.service';
import { Store } from '@ngxs/store';
import { TaskDataEntryState } from '../state-management/states';
import { InstanceService } from './instance.service';
import { ReportStatement } from '../models/report-statement';
import { BehaviorSubject, of } from 'rxjs';
import { UtilService } from '../../doc-process-common/services/util.service';
import { SetComponentData } from '../state-management/actions';
import { Quantity } from '../models/quantity';

@Injectable({
  providedIn: null,
})
export class LabelingHelperService {
  isDeltaDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.componentOptionsService.componentOptions.getValue().default_delta_display); // initial default

  constructor(private componentOptionsService: ComponentService, private store: Store, private instanceService: InstanceService) {}

  public hasIndividualQuantifiers(): boolean {
    return this.componentOptionsService.componentOptions.getValue().individual_quantifiers;
  }

  public hasConsolidatedModules(): boolean {
    return this.componentOptionsService.componentOptions.getValue().consolidate_modules;
  }

  public hasConsolidatedAnnotations(): boolean {
    return this.componentOptionsService.componentOptions.getValue().consolidate_annotations;
  }

  public hasSanityCheck(): boolean {
    return this.componentOptionsService.componentOptions.getValue().sanity_check;
  }

  public getQuantity(): Quantity {
    return this.store.selectSnapshot<ReportStatement>(TaskDataEntryState.selectComponentData<ReportStatement>(this.instanceService.getInstanceId(), this.componentOptionsService.getComponent()))
      .quantity;
  }

  public setDocumentQuantity(val: any) {
    const componentObject = UtilService.copyObject(
      this.store.selectSnapshot<ReportStatement>(TaskDataEntryState.selectComponentData<ReportStatement>(this.instanceService.getInstanceId(), this.componentOptionsService.getComponent()))
    ) as ReportStatement;

    componentObject.quantity = val;
    this.store.dispatch(new SetComponentData(this.instanceService.getInstanceId(), this.componentOptionsService.getComponent(), componentObject));
  }

  public getIsFullyAnnotated(): boolean | null {
    return this.store.selectSnapshot<ReportStatement>(TaskDataEntryState.selectComponentData<ReportStatement>(this.instanceService.getInstanceId(), this.componentOptionsService.getComponent()))
      .is_fully_annotated;
  }

  public setIsFullyAnnotated(val: boolean): void {
    const componentObject = UtilService.copyObject(
      this.store.selectSnapshot<ReportStatement>(TaskDataEntryState.selectComponentData<ReportStatement>(this.instanceService.getInstanceId(), this.componentOptionsService.getComponent()))
    ) as ReportStatement;

    componentObject.is_fully_annotated = val;
    this.store.dispatch(new SetComponentData(this.instanceService.getInstanceId(), this.componentOptionsService.getComponent(), componentObject));
  }

  public calculateModuleValueRep(value): number {
    const quantity = this.getQuantity();
    if (quantity) {
      return quantity.multiplier * value;
    } else {
      return value;
    }
  }

  public toggleIsDeltaDisplayed(isDisplayed) {
    this.isDeltaDisplayed$.next(!isDisplayed);
  }

  public getIsUnavailable(): boolean {
    return this.store.selectSnapshot<ReportStatement>(TaskDataEntryState.selectComponentData<ReportStatement>(this.instanceService.getInstanceId(), this.componentOptionsService.getComponent()))
      .is_unavailable;
  }

  public setIsUnavailable(val: boolean): void {
    const componentObject = UtilService.copyObject(
      this.store.selectSnapshot<ReportStatement>(TaskDataEntryState.selectComponentData<ReportStatement>(this.instanceService.getInstanceId(), this.componentOptionsService.getComponent()))
    ) as ReportStatement;

    componentObject.is_unavailable = val;
    this.store.dispatch(new SetComponentData(this.instanceService.getInstanceId(), this.componentOptionsService.getComponent(), componentObject));
  }
}
