import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeAll, shareReplay } from 'rxjs/operators';
import { Query } from '../../doc-process-common/services/methods.service';
import { ApiService } from '../../doc-process-common/services/api.service';
import { DocProcessService } from '../../doc-process-common/services/doc-process.service';
import { AutoCompleteAnnotationsResponseContract } from '../interfaces/auto-complete-annotations-response-contract';
import { TaskDataEntryState } from '../state-management/states';
import { Store } from '@ngxs/store';
import { Task, taskInstancesToInstanceIds } from '../models/Task';
import { Instance } from '../interfaces/instance-data';
import { FundamentalsAnnotation, InstanceDocument } from '../interfaces/fields';

@Injectable({
  providedIn: 'root',
})
export class DynamicApiService {
  public updatingInstanceData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private docProcessService: DocProcessService, private apiService: ApiService, private store: Store) {}

  getInstanceDocumentsforInstances<T extends InstanceDocument>(instances$: Observable<Instance[]>, taskInstances: Observable<Task>): Observable<{ [id: number]: T }> {
    const queryResponse: Observable<Array<T>> = instances$.pipe(
      map((instances: Instance[]) => {
        const res = this.apiService.httpGet<Array<T>>({ params: this.instancesToDocIds(instances) }, Query.GetDocument);
        return res;
      }),
      mergeAll(),
      shareReplay()
    );

    return queryResponse.pipe(
      map((instanceDocuments: Array<T>) => {
        // convert array to an object where id of each array element is a key to that element.
        const newRes = instanceDocuments.reduce((a: { [id: number]: T }, b: T) => {
          a[b.doc_id] = b;
          return a;
        }, {} as { [id: number]: T });

        return newRes;
      })
    );
  }

  //gets instanceDetails and annotations together from refactored endpoint
  public getInstanceDetails<T>(task$: Observable<Task>): Observable<any> {
    //const taskInstances = taskInstancesToInstanceIds$(task$).pipe(take(1)).subscribe();
    const instanceDataResponse = task$.pipe(
      map((taskInstances) => {
        const res = this.apiService.httpGet({ params: taskInstancesToInstanceIds(taskInstances) }, Query.GetInstanceDetails);
        return res;
      }),
      mergeAll(),
      shareReplay()
    );

    const instanceDetails = instanceDataResponse.pipe(
      map((res: any) => {
        return res.instances;
      })
    );

    return instanceDetails;
  }

  private instancesToDocIds(instances: Instance[]): string {
    const serialized: string = instances.reduce((a: string, b: Instance) => a + b.doc_id + ',', '');
    return serialized.substring(0, serialized.length - 1); // it removes comma at the end
  }

  public getInstanceDetailsBulk<T>(taskInstances: Observable<Task>): Observable<{ [id: number]: T }> {
    const instanceDataResponse = taskInstances.pipe(
      map((taskInstances) => {
        const res = this.apiService.httpGet({ params: taskInstancesToInstanceIds(taskInstances) }, Query.GetInstanceData);
        return res;
      }),
      mergeAll(),
      shareReplay()
    );

    const instanceDetails: Observable<{ [id: number]: T }> = instanceDataResponse.pipe(
      map((res: any) => {
        return res.instances;
      })
    );

    return instanceDetails;
  }

  public getInstanceJsonAnnotationsBulk(taskInstancesString$: Observable<string>): Observable<{
    [key: number]: Array<FundamentalsAnnotation> | null;
  }> {
    const instanceJsonAnnotationsResponse = taskInstancesString$.pipe(
      map((taskInstancesString: string) => {
        const res = this.apiService.httpGet({ params: taskInstancesString }, Query.GetJsonAnnotations);
        return res;
      }),
      mergeAll(),
      shareReplay()
    );

    const jsonAnnotations = instanceJsonAnnotationsResponse.pipe(
      map((res: any) => {
        const newRes = res.reduce((a, b) => {
          a[b.instance_id] = b?.annotations ?? [];
          return a;
        }, {});
        return newRes;
      })
    );

    return jsonAnnotations;
  }

  //TODO: Create model?
  public submitAutoCompleteRequest(currentInstanceId, previousInstanceId, statementId) {
    const sourceAnnotations = this.store.selectSnapshot(TaskDataEntryState.selectInstanceJsonAnnotationById(previousInstanceId));
    const targetAnnotations = this.store.selectSnapshot(TaskDataEntryState.selectInstanceJsonAnnotationById(currentInstanceId));
    const sourceInstance = { annotations: sourceAnnotations, instance_id: previousInstanceId };
    const targetInstance = { annotations: targetAnnotations, instance_id: currentInstanceId, statement_id: statementId };

    const body = {
      source_instance: sourceInstance,
      target_instance: targetInstance,
      task_id: this.docProcessService.taskId.getValue(),
    };

    return this.apiService.httpPost<AutoCompleteAnnotationsResponseContract>(
      {
        body: body,
        params: this.docProcessService.taskId.getValue().toString(),
      },
      Query.AutoCompleteAnnotations
    );
  }

  public submitInstanceDetails(instanceDetails) {
    this.apiService
      .httpPost(
        {
          body: instanceDetails,
          params: this.docProcessService.taskId.getValue().toString(),
        },
        Query.UpdateInstanceDetails
      )
      .subscribe(
        (res) => {
          this.docProcessService.unsavedData.next(false);
          this.docProcessService.displaySaveCompletedToastr(res);
        },
        (e) => {
          const responseMessage = e.data?.exception;
          this.docProcessService.displayResponseError(responseMessage);
          this.updatingInstanceData.next(false);
        },
        () => {
          this.updatingInstanceData.next(false);
        }
      );
  }
}
