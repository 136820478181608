import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { FetchTaskDataEntryState, FetchTaskInstances, SaveTask, SetComponentData, SetStatementAnnotations, SetTaskId, UpdateDocuments, SetSelectedInstanceId } from './actions';
import { DataEntryTaxonomyDetails } from '../models/data-entry-taxonomy-details';
import { DocProcessService } from '../../doc-process-common/services/doc-process.service';
import { map, take } from 'rxjs/operators';
import { DynamicApiService } from '../services/dynamic-api.service';
import { Task } from '../models/Task';
import { combineLatest, of } from 'rxjs';
import { DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { patch, updateItem } from '@ngxs/store/operators';
import { ReportStatement } from '../models/report-statement';
import { DocumentsLazyLoadingService } from '../services/documents-lazy-loading.service';
import { InstanceDetails } from '../models/instance-details.model';
import { FundamentalsAnnotation, InstanceDocument } from '../interfaces/fields';
import { Instance } from '../interfaces/instance-data';

export class TaskDataEntryStateModel {
  instanceDetails: Record<number, any>;
  instanceDocuments: Record<number, InstanceDocument>;
  instanceJsonAnnotations: Record<number, Array<FundamentalsAnnotation>>;
  taskId: number;
  taskInstances: Task;
  selectedInstanceId: number;
}

@State<TaskDataEntryStateModel>({
  name: 'TaskDataEntryState',
  defaults: {
    taskId: null,
    taskInstances: null,
    instanceDetails: null,
    instanceDocuments: null,
    instanceJsonAnnotations: null,
    selectedInstanceId: null,
  },
})
@Injectable()
export class TaskDataEntryState {
  constructor(private apiService: DynamicApiService, private docProcessService: DocProcessService, private store: Store, private documentsLazyLoadingService: DocumentsLazyLoadingService) {}

  @Selector()
  static selectTaskId(state: TaskDataEntryStateModel) {
    return state?.taskId;
  }

  static selectAllInstances() {
    return createSelector([TaskDataEntryState], (state: TaskDataEntryStateModel) => {
      return state.taskInstances.instances;
    });
  }

  static selectAllDocuments() {
    return createSelector([TaskDataEntryState], (state: TaskDataEntryStateModel) => {
      return state.instanceDocuments;
    });
  }

  static selectTaskInstanceByIndex(instanceIndex: number) {
    return createSelector([TaskDataEntryState], (state: TaskDataEntryStateModel) => {
      return state.taskInstances.instances[instanceIndex];
    });
  }

  static selectTaskInstanceById(instanceId: number) {
    return createSelector([TaskDataEntryState], (state: TaskDataEntryStateModel) => {
      return state.taskInstances.instances.find((instance) => instance.instance_id === instanceId);
    });
  }

  static selectTaskIndexById(instanceId: number) {
    return createSelector([TaskDataEntryState, TaskDataEntryState.selectTaskInstanceById(instanceId)], (state: TaskDataEntryStateModel, instance: Instance) => {
      const index: number = state.taskInstances.instances.indexOf(instance);
      return index;
    });
  }

  static selectPreviousTaskIndexById(instanceId: number) {
    return createSelector([TaskDataEntryState, TaskDataEntryState.selectTaskInstanceById(instanceId)], (state: TaskDataEntryStateModel, instance: Instance) => {
      const index: number = state.taskInstances.instances.indexOf(instance);
      return index - 1;
    });
  }

  static selectPreviousTaskInstance(instanceId: number) {
    return createSelector([TaskDataEntryState, TaskDataEntryState.selectPreviousTaskIndexById(instanceId)], (state: TaskDataEntryStateModel, taskIndex: number) => {
      if (taskIndex < 0) return null;

      return state.taskInstances.instances[taskIndex];
    });
  }

  static selectInstanceDocuments(instanceId: number) {
    return createSelector([TaskDataEntryState, TaskDataEntryState.selectTaskInstanceById(instanceId)], (state: TaskDataEntryStateModel) => {
      const docId = state.taskInstances.instances.find((taskInstance) => taskInstance.instance_id === instanceId).doc_id;
      return state.instanceDocuments?.[docId];
    });
  }

  static selectInstanceDetails(instanceId: number) {
    return createSelector([TaskDataEntryState], (state: TaskDataEntryStateModel) => {
      return state.instanceDetails[instanceId];
    });
  }

  static selectCompanyId(instanceId: number) {
    return createSelector([TaskDataEntryState], (state: TaskDataEntryStateModel) => {
      return state.instanceDetails[instanceId].doc_meta.company.company_id;
    });
  }

  static selectInstanceComponents(instanceId: number) {
    return createSelector([TaskDataEntryState], (state: TaskDataEntryStateModel) => {
      return state.instanceDetails[instanceId].components;
    });
  }

  static selectInstanceJsonAnnotationById(instanceId: number) {
    return createSelector([TaskDataEntryState], (state: TaskDataEntryStateModel) => {
      return state.instanceJsonAnnotations[instanceId];
    });
  }

  static selectInstanceJsonAnnotations(instanceId: number, component: DynamicDataEntryComponentInterface) {
    return createSelector([TaskDataEntryState, TaskDataEntryState.selectComponentData(instanceId, component)], (state: TaskDataEntryStateModel, componentData: DataEntryTaxonomyDetails) => {
      const jsonAnnotations = state.instanceJsonAnnotations[instanceId];
      const statementId: number = componentData.id;

      return jsonAnnotations.filter((jsonAnnotation: FundamentalsAnnotation) => jsonAnnotation.fields[0].context.statementId === statementId);
    });
  }

  static selectComponentData<T>(instanceId: number, component: DynamicDataEntryComponentInterface): any {
    return createSelector([TaskDataEntryState], (state: TaskDataEntryStateModel) => {
      const componentDataAddress: Array<string> = component.mapping.split(':');
      let instanceData = state.instanceDetails[instanceId];
      for (let addressNode of componentDataAddress) {
        if (addressNode.charAt(0) === '[') addressNode = addressNode.slice(1, -1);
        instanceData = instanceData[addressNode];
      }

      return instanceData as unknown as T;
    });
  }

  @Selector()
  static selectSelectedInstanceId(state: TaskDataEntryStateModel) {
    return state.selectedInstanceId || state.taskInstances.instances[0].instance_id;
  }

  @Selector()
  static selectTaskInstances(state: TaskDataEntryStateModel) {
    return state.taskInstances;
  }

  @Selector()
  static selectDocumentsBatchSize(state: TaskDataEntryStateModel) {
    return state.taskInstances.document_batch_size;
  }

  @Selector()
  static selectSidebarWidth(state: TaskDataEntryStateModel) {
    return state.taskInstances.instance_data_component_width;
  }

  @Selector()
  static selectIsTaskDataLoaded(state: TaskDataEntryStateModel) {
    if (!!state.taskId && !!state.taskInstances && !!state.instanceDocuments && !!state.instanceDetails) return true;
    return false;
  }

  @Action(SetTaskId)
  setTaskId(ctx: StateContext<TaskDataEntryStateModel>, { taskId }: { taskId: number }) {
    ctx.patchState({
      taskId: taskId,
    });

    ctx.dispatch(new FetchTaskInstances());
  }

  @Action(SetSelectedInstanceId)
  SetSelectedInstanceId(ctx: StateContext<TaskDataEntryStateModel>, { selectedInstanceId }: { selectedInstanceId: number }) {
    ctx.patchState({
      selectedInstanceId: selectedInstanceId,
    });
  }

  @Action(SetStatementAnnotations)
  setStatementAnnotations(
    ctx: StateContext<TaskDataEntryStateModel>,
    { instanceId, component, statementAnnotations }: { instanceId: number; component: DynamicDataEntryComponentInterface; statementAnnotations: FundamentalsAnnotation[] }
  ) {
    const statementId = this.store.selectSnapshot<ReportStatement>(TaskDataEntryState.selectComponentData(instanceId, component)).id;

    const instanceAnnotations: Array<FundamentalsAnnotation> = ctx.getState().instanceJsonAnnotations[instanceId];
    const otherStatementsAnnotations = instanceAnnotations.filter((jsonAnnotation: FundamentalsAnnotation) => jsonAnnotation.fields[0].context.statementId !== statementId);
    const newInstanceAnnotations = [...otherStatementsAnnotations, ...(statementAnnotations ?? [])];

    ctx.setState(
      patch({
        instanceJsonAnnotations: patch({
          [instanceId]: newInstanceAnnotations,
        }),
      })
    );
  }

  @Action(SetComponentData)
  setComponentData(
    ctx: StateContext<TaskDataEntryStateModel>,
    { instanceId, component, value, addressNodes }: { instanceId: number; component: DynamicDataEntryComponentInterface; value: any; addressNodes: any[] }
  ) {
    if (!addressNodes) {
      //gets addressNodes from mapping property
      const componentDataAddress: Array<string> = component.mapping.split(':');

      addressNodes = [];
      for (let colonSeperatedAddressIndicator of componentDataAddress) {
        if (colonSeperatedAddressIndicator.charAt(0) === '[') {
          colonSeperatedAddressIndicator = colonSeperatedAddressIndicator.slice(1, -1);
        }
        addressNodes.push(colonSeperatedAddressIndicator);
      }
    }

    let stateModifier = value;
    for (const addressNode of addressNodes.reverse()) {
      if (isNaN(addressNode)) {
        stateModifier = patch({
          [addressNode]: stateModifier,
        });
      } else {
        stateModifier = updateItem(parseInt(addressNode), stateModifier);
      }
    }

    ctx.setState(
      patch({
        instanceDetails: patch({
          [instanceId]: stateModifier,
        }),
      })
    );
  }

  @Action(FetchTaskInstances)
  fetchTaskInstances(ctx: StateContext<TaskDataEntryStateModel>) {
    this.docProcessService
      .getTaskInstances$(of(ctx.getState().taskId))
      .pipe(take(1))
      .subscribe((taskInstances) => {
        ctx.patchState({ taskInstances: taskInstances });
        ctx.dispatch(new FetchTaskDataEntryState());
      });
  }

  @Action(FetchTaskDataEntryState)
  fetchTaskDataEntryState(ctx: StateContext<TaskDataEntryStateModel>) {
    const taskInstances$ = of(ctx.getState().taskInstances);
    combineLatest([
      //this.apiService.getInstanceDetailsBulk<DataEntryTaxonomyDetails>(taskInstances$),
      this.apiService.getInstanceDetails<any>(taskInstances$),
      this.documentsLazyLoadingService.loadInitialBatch(taskInstances$.pipe(map((task: Task) => task.instances)), taskInstances$),
      // this.apiService.getInstanceJsonAnnotationsBulk(taskInstancesToInstanceIds$(taskInstances$)),
    ]).subscribe(([instanceDetails, instanceDocuments]) => {
      const instancesDetails = {};
      const instancesAnnotations = {};
      for (const instance of instanceDetails) {
        instance.instance_data.components = instance.components;
        instancesDetails[instance.instance_id] = instance.instance_data;
        instancesAnnotations[instance.instance_id] = instance.annotations;
      }
      ctx.patchState({
        instanceDetails: instancesDetails,
        instanceDocuments: instanceDocuments,
        instanceJsonAnnotations: instancesAnnotations,
      });
    });
  }

  @Action(UpdateDocuments)
  updateDocuments(ctx: StateContext<TaskDataEntryStateModel>, instanceDocuments) {
    const docs = ctx.getState().instanceDocuments;
    ctx.patchState({
      instanceDocuments: { ...docs, ...instanceDocuments.documents },
    });
  }

  @Action(SaveTask)
  saveTask(ctx: StateContext<TaskDataEntryStateModel>, { instanceIdsToSubmit }: { instanceIdsToSubmit: Array<any> }) {
    const instanceJsonAnnotations: { [p: number]: Array<FundamentalsAnnotation> | null } = ctx.getState().instanceJsonAnnotations;

    DocProcessService.filterInstancesBySubmissionCheckbox(instanceJsonAnnotations, instanceIdsToSubmit);

    const instanceJsonAnnotationsObject = {};

    Object.keys(instanceJsonAnnotations).forEach((instanceJsonAnnotationKey) => {
      instanceJsonAnnotationsObject[instanceJsonAnnotationKey] = {
        instance_id: instanceJsonAnnotationKey,
        annotations: instanceJsonAnnotations[instanceJsonAnnotationKey],
      };
    });

    const updateInstanceDataEndpointParams = ctx.getState().instanceDetails;
    const instancesToSave = { ...updateInstanceDataEndpointParams };
    DocProcessService.filterInstancesBySubmissionCheckbox(instancesToSave, instanceIdsToSubmit);
    const instancesWithAnnotations = { instances: [] };

    Object.keys(instancesToSave).forEach((InstanceId) => {
      const instanceWithAnnotations = new InstanceDetails(Number(InstanceId), { ...instancesToSave[InstanceId] }, instanceJsonAnnotations[InstanceId]);
      instanceWithAnnotations.removeComponents();
      instancesWithAnnotations.instances.push(instanceWithAnnotations);
    });

    this.apiService.submitInstanceDetails(instancesWithAnnotations);
  }
}
