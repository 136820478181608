import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { DynamicDataEntryComponentTypeEnum } from '../models/dynamic-data-entry-component-type-enum';
import { Store } from '@ngxs/store';
import { TaskDataEntryState } from '../state-management/states';

@Component({
  selector: 'con-dynamic-data-entry-component-container',
  styles: [
    `
      .nav-link {
        height: 100%;
      }
      .icon-spacer {
        display: inline-block;
        width: 0.5rem;
      }
      .component-item {
        max-width: 100%;
      }
      .selected {
        background-color: rgb(209, 236, 241);
        padding: 0.5rem;
        margin-bottom: 0.5rem;
      }
    `,
  ],
  template: `
    <!--if tab list-->
    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.TabComponents">
      <ul ngbNav #nav="ngbNav" class="nav-tabs flex d-flex w-100 justify-content-start flex-nowrap mb-2" [(activeId)]="activeMenuTab">
        <ng-container *ngFor="let eachComponent of component.components">
          <li [ngbNavItem]="eachComponent.title" class="flex-grow-1">
            <con-dynamic-tab-component [component]="eachComponent" [isSelected]="selectedComponents?.includes(eachComponent.title)"></con-dynamic-tab-component>
            <ng-template ngbNavContent>
              <dp-dynamic-data-entry-component-wrapper style="float: none; display: inline" [component]="eachComponent" [activeMenuTabId]="activeMenuTab"></dp-dynamic-data-entry-component-wrapper>
            </ng-template>
          </li>
        </ng-container>
      </ul>

      <div [ngbNavOutlet]="nav"></div>
    </ng-container>

    <!--if list of components-->
    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.ListOfComponents">
      <div class="d-flex flex-wrap flex-column">
        <div *ngFor="let eachComponent of component.components" class="component-item" [ngClass]="{ selected: selectedComponents?.includes(eachComponent.title) }">
          <label *ngIf="component.options?.display_title !== false && eachComponent.title">
            <span class="icon-spacer" *ngIf="eachComponent.confirmation"></span><small>{{ eachComponent.title }}</small>
          </label>
          <dp-dynamic-data-entry-component-wrapper [isSelected]="selectedComponents?.includes(eachComponent.title)" [component]="eachComponent"></dp-dynamic-data-entry-component-wrapper>
        </div>
      </div>
    </ng-container>
  `,
})
export class DynamicDataEntryComponentContainerComponent implements OnInit {
  @Input() component: DynamicDataEntryComponentInterface;

  public activeMenuTab: any;
  public selectedComponents: string[];

  get DynamicDataEntryComponentTypeEnum() {
    return DynamicDataEntryComponentTypeEnum;
  }

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.selectedComponents = this.store.selectSnapshot(TaskDataEntryState.selectTaskInstances).current_fields;
  }
}
