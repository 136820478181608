import { Injectable, OnDestroy } from '@angular/core';
import { UtilService } from '../../doc-process-common/services/util.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { HighlightEvent } from '../../doc-process-common/models/highlight-event';
import { AnnotationModel } from '../../doc-process-common/models/annotation-model';
import { IdentifierModel } from '../../doc-process-common/models/identifier-model';
import { map, switchMapTo, take, takeUntil } from 'rxjs/operators';
import { PersistentRange } from '../../doc-process-common/models/persistent-range';
import { DecimalSeparator } from '../../doc-process-common/models/decimal-separator';
import RangeOffsetModel from '../../doc-process-common/models/range-offset.model';
import { SignEnum } from '../../doc-process-common/models/sign-enum';
import { ReportStatement } from '../models/report-statement';
import { TaxonomyModule } from '../../doc-process-common/models/taxonomy-module';
import { DocProcessService } from '../../doc-process-common/services/doc-process.service';
import { InstanceService } from './instance.service';
import { DynamicApiService } from './dynamic-api.service';
import { MarkModel } from '../../doc-process-common/models/mark-model';
import { SetStatementAnnotations } from '../state-management/actions';
import { Store } from '@ngxs/store';
import { LabelingHelperService } from './labeling-helper.service';
import { ComponentService } from './component.service';
import { AutoCompleteAnnotationsResponseContract } from '../interfaces/auto-complete-annotations-response-contract';
import { FundamentalsAnnotation } from '../interfaces/fields';

@Injectable({
  providedIn: null,
})
export class FundamentalsStatementService implements OnDestroy {
  markEventStream: Subject<HighlightEvent> = new Subject();
  annotations: BehaviorSubject<Array<AnnotationModel>> = new BehaviorSubject<Array<AnnotationModel>>([]);
  focusedMarkId: BehaviorSubject<IdentifierModel> = new BehaviorSubject<IdentifierModel>(null);
  onMarkClick: Subject<IdentifierModel> = new Subject<IdentifierModel>();
  private subscribeUntil: Subject<boolean> = new Subject();
  initialAnnotationsLoader: BehaviorSubject<[Array<FundamentalsAnnotation>, ReportStatement]> = new BehaviorSubject<[Array<FundamentalsAnnotation>, ReportStatement]>([[], null]);
  isInitialAnnotationsLoadedBefore = false;
  decimalSelector: BehaviorSubject<DecimalSeparator> = new BehaviorSubject<DecimalSeparator>(DecimalSeparator.Auto);
  createAnnotationSubject: BehaviorSubject<any>;
  documentMarks: BehaviorSubject<Array<MarkModel>> = new BehaviorSubject<Array<MarkModel>>(new Array<MarkModel>());
  statement: BehaviorSubject<ReportStatement> = new BehaviorSubject<ReportStatement>(null);
  waitingAutoCompleteResponse: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  scrollToMark: Subject<IdentifierModel> = new Subject<IdentifierModel>();

  //todo refactoring - move some logic to labeling service and others
  constructor(
    private docProcessService: DocProcessService,
    private instanceService: InstanceService,
    private fundamentalsApiService: DynamicApiService,
    private store: Store,
    private componentOptionsService: ComponentService,
    private labelingHelperService: LabelingHelperService
  ) {
    //todo: it shouldn't be here ;(
    this.createAnnotationSubject = new BehaviorSubject<[PersistentRange, TaxonomyModule, SignEnum]>(null);
    this.initialAnnotationsLoader.pipe(takeUntil(this.subscribeUntil)).subscribe(([initialAnnotations, reportStatement]: [Array<FundamentalsAnnotation>, ReportStatement]) => {
      if (this.isInitialAnnotationsLoadedBefore === true) return;

      if (initialAnnotations.length <= 0) return;

      this.annotations.next([]);
      this.documentMarks.next([]);

      this.createAnnotationsBulk(initialAnnotations, reportStatement);

      setTimeout(() => {
        this.isInitialAnnotationsLoadedBefore = true;
        this.docProcessService.unsavedData.next(false);
      }, 500);
    });

    this.createAnnotationSubject.subscribe((data: [PersistentRange, TaxonomyModule, SignEnum, any, number]) => {
      if (data) this.createAnnotation(...data);
    });

    this.documentMarks.subscribe((val) => {});

    combineLatest([this.documentMarks, this.annotations])
      .pipe(
        switchMapTo(this.annotations)
        // debounceTime(50),
      )
      .subscribe((val) => {
        setTimeout(() => {
          this.annotationsToStore();
        }, 0);
      });
  }

  public annotationsToStore() {
    const taxonomyAnnotationsArray: FundamentalsAnnotation[] = this.consolidateStatement();
    this.store.dispatch(new SetStatementAnnotations(this.instanceService.getInstanceId(), this.componentOptionsService.getComponent(), taxonomyAnnotationsArray));
  }

  public consolidateStatement(): FundamentalsAnnotation[] {
    const taxonomyAnnotationsArray: FundamentalsAnnotation[] = [];

    for (const annotation of this.annotations.getValue()) {
      taxonomyAnnotationsArray.push({
        annot_id: annotation.id.toInt(),
        conf: 1,
        spans: [
          {
            offsets: [annotation.getMark().range.rangeOffset.start, annotation.getMark().range.rangeOffset.end],
            xref: annotation.getMark().range.rangeOffset.relativeXpath,
            text: annotation.getMark().range.innerString,
          },
        ],
        fields: [
          {
            context: {
              sign: annotation.getMark().sign === 1 ? 'p' : 'n',
              subtract: annotation.subtract,
              quantity: annotation?.quantity ?? annotation?.module?.quantity ?? this.labelingHelperService.getQuantity(),
              statementId: this.statement.getValue().id,
              decimalSeparator: this.decimalSelector.getValue(),
              scalarValue: annotation.getMark().getScalarValue(this.decimalSelector.getValue()),
            },
            field: annotation.module.unique_id,
          },
        ],
      } as FundamentalsAnnotation);
    }

    return taxonomyAnnotationsArray;
  }

  private createAnnotationsBulk(initialAnnotations: Array<FundamentalsAnnotation>, reportStatement: ReportStatement) {
    initialAnnotations.forEach((fundamentalsAnnotation: FundamentalsAnnotation) => {
      const persistentRange = new PersistentRange(
        new RangeOffsetModel(fundamentalsAnnotation.spans[0].offsets[0], fundamentalsAnnotation.spans[0].offsets[1], fundamentalsAnnotation.spans[0].xref),
        fundamentalsAnnotation.spans[0].text
      );

      if (persistentRange === null || fundamentalsAnnotation === null) {
        console.warn('persistentRange or fundamentalsAnnotation is null');
        return;
      }

      const sign = fundamentalsAnnotation.fields[0].context.sign === 'n' ? SignEnum.Negative : SignEnum.Positive;
      const moduleData = ReportStatement.findModule(reportStatement, fundamentalsAnnotation.fields[0].field);
      const subtract = fundamentalsAnnotation.fields[0].context.subtract;
      const quantity = fundamentalsAnnotation.fields[0].context.quantity;
      const id = fundamentalsAnnotation.annot_id;
      this.createAnnotationSubject.next([persistentRange, moduleData, sign, subtract, quantity, id]);
    });
  }

  ngOnDestroy(): void {
    this.subscribeUntil.next(true);
  }

  public addAnnotation(annotation: AnnotationModel) {
    this.annotations.next([...this.annotations.getValue(), annotation]);
    this.docProcessService.unsavedData.next(true);
  }

  public removeAnnotation(id: IdentifierModel) {
    this.annotations.next(this.annotations.getValue().filter((annotation: AnnotationModel) => annotation.id !== id));
    this.docProcessService.unsavedData.next(true);
  }

  public getAnnotationById(id: IdentifierModel): AnnotationModel {
    return this.annotations.getValue().find((annotation: AnnotationModel) => annotation.id.toString() === id.toString()); // typescript doesn't support operator overloading. :(
  }

  public static isMarkOverlapping(highlightEvent: HighlightEvent) {
    return highlightEvent.range.startContainer.parentElement.tagName.toLowerCase() === 'mark' || highlightEvent.range.endContainer.parentElement.tagName.toLowerCase() === 'mark';
  }

  public static isMultipleNode(highlightEvent: HighlightEvent): boolean {
    return highlightEvent.range.startContainer !== highlightEvent.range.endContainer;
  }

  public filterAnnotationsByModuleId(annotations: Observable<Array<AnnotationModel>>, moduleId: number): Observable<Array<AnnotationModel>> {
    return annotations.pipe(
      map((annotations: Array<AnnotationModel>) => {
        return annotations.filter((annotation: AnnotationModel) => annotation.module.module_id === moduleId);
      })
    );
  }

  public getAnnotationListByModuleId(moduleId: number): Observable<Array<AnnotationModel>> {
    return this.filterAnnotationsByModuleId(this.annotations, moduleId);
  }

  public static isMarkEmpty(highlightEvent: HighlightEvent): boolean {
    if (highlightEvent.range.toString().length > 0) return false;
    return true;
  }

  public updateAnnotation(modifiedAnnotation: AnnotationModel) {
    let annotation: AnnotationModel = this.getAnnotationById(modifiedAnnotation.id);
    annotation = modifiedAnnotation;
    this.store.dispatch(new SetStatementAnnotations(this.instanceService.getInstanceId(), this.componentOptionsService.getComponent(), this.consolidateStatement()));
    this.docProcessService.unsavedData.next(true);
  }

  private sanityValueMatch(v1: number, v2: number): boolean {
    return 0.66 < v1 / v2 && v1 / v2 < 1.5;
  }

  public sanityValidation(annotationList: Array<AnnotationModel>): boolean {
    if (annotationList.length) {
      const decimalSelector = this.decimalSelector.getValue();
      const firstQantityMultiplier = annotationList[0].quantity.multiplier;
      const firstValue = annotationList[0].toConsolidationFactor(decimalSelector) * firstQantityMultiplier;

      let isValid = true;
      annotationList.forEach((annotation) => {
        const valueMatch = this.sanityValueMatch(firstValue, annotation.toConsolidationFactor(decimalSelector) * annotation.quantity.multiplier);

        if (valueMatch) {
          annotation.passedSanityCheck = true;
          this.updateAnnotation(annotation);
        } else {
          annotation.passedSanityCheck = false;
          this.updateAnnotation(annotation);
          isValid = false;
        }
      });
      return isValid;
    }
  }

  public autoCompleteStatement(statement: ReportStatement): void {
    const currentInstanceId = this.instanceService.getInstanceId();
    const currentInstance = this.instanceService.getTaskInstanceById(currentInstanceId);
    let previousInstance;
    let previousInstanceId;
    let isNotFirstStatement = false;
    const componentDataAddress: Array<string> = this.componentOptionsService.getComponent().mapping.split(':');

    for (let addressNode of componentDataAddress) {
      if (addressNode.charAt(0) === '[') addressNode = addressNode.slice(1, -1);
      if (new UtilService().isNumeric(addressNode)) {
        if (parseInt(addressNode) >= 1) {
          isNotFirstStatement = true;
        }
      }
    }

    if (isNotFirstStatement) {
      // source is previous statement
      previousInstanceId = currentInstanceId;
    } else {
      // source is previous instance
      previousInstanceId = this.instanceService.getPrevInstanceId(currentInstanceId);

      if (!previousInstanceId) {
        this.docProcessService.displaySourceInstanceInvalidError();
        return;
      }
      previousInstance = this.instanceService.getTaskInstanceById(previousInstanceId);
      const currentIssuerId = currentInstance.issuer_id;
      const prevIssuerId = previousInstance.issuer_id;

      if (currentIssuerId !== prevIssuerId) {
        this.docProcessService.displaySourceInstanceInvalidError();
        return;
      }
    }

    this.fundamentalsApiService.submitAutoCompleteRequest(currentInstanceId, previousInstanceId, statement.id).subscribe(
      (response: AutoCompleteAnnotationsResponseContract) => {
        const newAnnotations: Array<FundamentalsAnnotation> = response.updated_annotations;
        this.replaceAnnotationsWith(newAnnotations, statement);
      },
      (err) => this.docProcessService.displayResponseError(err.data)
    );
  }

  public getAnnotationListAfterMarkSubmitted(): Observable<Array<AnnotationModel>> {
    return combineLatest([this.documentMarks, this.annotations]).pipe(switchMapTo(this.annotations));
  }

  public getMarkOfAnnotation(annotationId: IdentifierModel): Observable<MarkModel> {
    return this.documentMarks.pipe(
      map((marks: MarkModel[]) => {
        const mark: MarkModel = marks.find((mark: MarkModel) => {
          if (mark.annotationBindings.find((annotation: AnnotationModel) => annotation.id.toString() === annotationId.toString())) return true;
          else return false;
        });
        return mark;
      })
    );
  }

  public getMarkOfAnnotationSequential(annotationId: IdentifierModel): MarkModel {
    const marks = this.documentMarks.getValue();
    const mark: MarkModel = marks.find((mark: MarkModel) => {
      if (mark.annotationBindings.find((annotation: AnnotationModel) => annotation.id.toString() === annotationId.toString())) return true;
      else return false;
    });
    return mark;
  }

  public addMark(mark: MarkModel) {
    this.documentMarks.next([...this.documentMarks.getValue(), mark]);
    UtilService.logIfAdmin('new documentMarks value:');
    UtilService.logIfAdmin(this.documentMarks.getValue());
    this.docProcessService.unsavedData.next(true);
  }

  public removeMark(id: IdentifierModel) {
    const markToRemove = this.documentMarks?.getValue()?.find((eachMark) => eachMark?.id?.toString() === id?.toString());

    if (!markToRemove) return;

    markToRemove.annotationBindings.forEach((eachAnnotationBinding: AnnotationModel) => {
      this.removeAnnotation(eachAnnotationBinding.id);
    });

    const newDocumentMarks = this.documentMarks.getValue().filter((eachMark: MarkModel) => eachMark.id.toString() !== id.toString());

    this.documentMarks.next(newDocumentMarks);
    this.docProcessService.unsavedData.next(true);
  }

  addAnnotationToMark(markGuid: IdentifierModel, newAnnotation: AnnotationModel) {
    const documentMarks = this.documentMarks.getValue();
    documentMarks.find((mark: MarkModel) => mark.id.toString() === markGuid.toString()).annotationBindings.push(newAnnotation);
    this.documentMarks.next(documentMarks);
    this.docProcessService.unsavedData.next(true);
  }

  public static getMarkObjectFromElement(node): IdentifierModel {
    return new IdentifierModel((JSON.parse(node.getAttribute('data-mark-id')) as IdentifierModel).id);
  }

  public switchMarkSign(mark: Observable<MarkModel>) {
    mark.pipe(take(1)).subscribe((switchedMark: MarkModel) => {
      const documentMarks = this.documentMarks.getValue();
      const markToSwitch = documentMarks.find((eachMark: MarkModel) => eachMark.id.toString() === switchedMark.id.toString());
      markToSwitch.alterSign();
      this.documentMarks.next(documentMarks);
      this.docProcessService.unsavedData.next(true);
    });
  }

  createAnnotation(persistentRange: PersistentRange, moduleData: TaxonomyModule, sign?: SignEnum, subtract?: boolean, quantity?: any, id?: number) {
    if (/\d/.test(persistentRange.innerString)) {
      const newAnnotation = new AnnotationModel(moduleData, this, subtract, quantity, id);
      const multiAnnotationMarkId: IdentifierModel = this.findMarkByRange(persistentRange)?.id;
      if (multiAnnotationMarkId) this.addAnnotationToMark(multiAnnotationMarkId, newAnnotation);
      else {
        const newMark = new MarkModel(persistentRange, newAnnotation, sign ?? UtilService.detectSign(persistentRange.innerString));
        this.addMark(newMark);
        this.focusedMarkId.next(newMark.id);
      }

      this.addAnnotation(newAnnotation);
    }
  }

  private findMarkByRange(persistentRange: PersistentRange): MarkModel {
    return this.documentMarks.getValue().find((mark) => PersistentRange.compare(mark.range, persistentRange) === 0);
  }

  public switchSignOfSelectedMark(): void {
    const selectedMarkId: IdentifierModel = this.focusedMarkId.getValue();
    if (!selectedMarkId) return;

    const documentMarks = this.documentMarks.getValue();
    const selectedMark = documentMarks.find((documentMark: MarkModel) => IdentifierModel.compare(selectedMarkId, documentMark?.id) === 0);
    if (!selectedMark) return;

    selectedMark.alterSign();
    this.documentMarks.next(documentMarks);
    this.docProcessService.unsavedData.next(true);
  }

  public clearStatement(): void {
    this.annotations.next([]);
    this.documentMarks.next([]);
  }

  public getStatementAnnotations(statementId: number): Observable<Array<FundamentalsAnnotation>> {
    const instanceId: string = this.instanceService.getInstanceId().toString();
    const statementAnnotations = this.fundamentalsApiService.getInstanceJsonAnnotationsBulk(of(instanceId)).pipe(
      map((taskAnnotations: { [key: number]: Array<FundamentalsAnnotation> | null }) => {
        const instanceAnnotations = taskAnnotations[instanceId];
        const statementAnnotations = instanceAnnotations.filter((annotation) => annotation.fields[0].context.statementId === statementId);
        return statementAnnotations;
      })
    );
    return statementAnnotations;
  }

  public replaceAnnotationsWith(newAnnotations: Array<FundamentalsAnnotation>, statement: ReportStatement) {
    this.clearStatement();
    this.createAnnotationsBulk(newAnnotations, statement);
    this.waitingAutoCompleteResponse.next(false);
  }

  public reloadStatementAnnotations(reportStatement: ReportStatement) {
    this.waitingAutoCompleteResponse.next(true);

    const statementAnnotations$: Observable<Array<FundamentalsAnnotation>> = this.getStatementAnnotations(reportStatement.id);

    statementAnnotations$.subscribe((statementAnnotations: Array<FundamentalsAnnotation>) => {
      this.replaceAnnotationsWith(statementAnnotations, reportStatement);
    });
  }
}
