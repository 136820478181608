import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { TaskDataEntryState } from '../state-management/states';
import { InstanceService } from '../services/instance.service';
import { Instance } from '../interfaces/instance-data';

@Component({
  selector: 'con-doc-process-counter',
  template: ` <div class="pt-2">Instance {{ instanceIndex + 1 }} of {{ instanceDefinitions?.length }}</div> `,
  styles: [
    `
      :host {
        display: block;
        margin-right: -15px;
      }
    `,
  ],
})
export class CounterComponent implements OnInit {
  instanceIndex = 0;
  instanceDefinitions: Array<Instance>;

  constructor(private store: Store, private instanceService: InstanceService) {}

  ngOnInit(): void {
    this.instanceDefinitions = this.store.selectSnapshot(TaskDataEntryState.selectAllInstances());
    this.store.select(TaskDataEntryState.selectSelectedInstanceId).subscribe((id) => {
      if (id && this.instanceDefinitions) {
        this.instanceIndex = this.instanceDefinitions.findIndex((object) => {
          return object.instance_id === id;
        });
      }
    });
  }
}
