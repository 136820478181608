import { Component, Input } from '@angular/core';
import { DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { DynamicDataEntryComponentTypeEnum } from '../models/dynamic-data-entry-component-type-enum';
import { IdentifierModel } from '../../doc-process-common/models/identifier-model';
import { InstanceService } from '../services/instance.service';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ComponentService } from '../services/component.service';
import { DynamicDataEntryAbstractInput } from './dynamic-data-entry-abstract-input.component';
import { Hotkeys } from '../services/hotkeys.service';
import { InstanceDataValue } from '../interfaces/instance-data';

@Component({
  selector: 'con-dynamic-data-entry-date-input-component',
  template: `
    <con-dynamic-confirmation-icon *ngIf="isConfirmable" [showEmpty]="this.showEmpty" [isConfirmed]="this.isConfirmed"></con-dynamic-confirmation-icon>
    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.DateSelectorComponent">
      <div class="form-group">
        <con-date-picker [dateObject]="componentData" (dateEmitter)="onDateValidationAssessment($event)"></con-date-picker>
      </div>
    </ng-container>
  `,
})
export class DynamicDataEntryDateInputComponent extends DynamicDataEntryAbstractInput {
  @Input() component: DynamicDataEntryComponentInterface;
  public dateObject$: Observable<InstanceDataValue | Partial<InstanceDataValue>>;
  public isDateValid: boolean;

  constructor(store: Store, instanceService: InstanceService, componentService: ComponentService, hotkeys: Hotkeys) {
    super(store, instanceService, componentService, hotkeys);
  }

  get DynamicDataEntryComponentTypeEnum() {
    return DynamicDataEntryComponentTypeEnum;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  public onDateValidationAssessment($event: [boolean, IdentifierModel, InstanceDataValue]): void {
    const instanceDate: string = $event[2].value;
    this.isDateValid = $event[0];

    if (this.isDateValid === false) {
      return;
    }
    this.componentValueToStore(instanceDate);
  }
}
