import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { Hotkeys } from '../services/hotkeys.service';
import { Store } from '@ngxs/store';
import { InstanceService } from '../services/instance.service';

@Component({
  selector: 'con-dynamic-factsheet-input',
  template: `
<input class="form-control align-middle w-100"  step="any"  type="{{ inputType }}" placeholder="" [ngModel]="inputModel" (ngModelChange)="updateValue($event)"/>
  `,
  styles: [
    `

      th {
        border: none;
        text-transform: capitalize;
      }

      td {
        text-align: center;
      }

      th.rotate {
        height: 80px;
        width: 30px;
        white-space: nowrap;
      }

      th.rotate > div {
        transform: translate(-4px, 3px) rotate(315deg);
        width: 30px;
      }
      th.rotate > div > span {
        padding: 5px 10px;
      }

     .form-control {
       padding: 0.2rem;
       font-size: 0.8rem;
     }


    `,
  ],
})
export class DynamicFactsheetInputComponent implements OnInit {
  @Input() component: DynamicDataEntryComponentInterface;
  @Input() inputData: any;
  public inputType: string;
  public inputModel: string;
  @Output() updateEvent = new EventEmitter<any>();



  constructor(private hotkes: Hotkeys, private store: Store, private instanceService: InstanceService) {}

  ngOnInit(): void {
    this.inputModel = this.inputData[this.component.mapping];
    this.inputType = this.component.component_type === 'numerical-input' ? 'number' : 'text';

  }

  updateValue($event: any) {
    if (this.inputType === 'number') {
      $event = Number($event);
    }
   this.updateEvent.emit({value: $event, mapping: this.component.mapping});
  }
}
