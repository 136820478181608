import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilService } from '../../doc-process-common/services/util.service';
import { Router } from '@angular/router';
import { ZoomService } from '../../doc-process-common/services/zoom.service';

@Component({
  selector: 'doc-process-annotate-view-header',
  template: `
    <div class="d-flex">
      <!-- go back button -->
      <button (click)="onGoBackButtonClick()" class="btn btn-primary">
        <con-icon-by-name class="mr-3" iconName="long-arrow-left"></con-icon-by-name>
        Go back
      </button>

      <!-- confirm button -->
      <button (click)="onConfirmButtonClick()" class="btn btn-success ml-1">
        Save Selected
        <con-icon-by-name class="ml-2" iconName="check"></con-icon-by-name>
      </button>

      <!-- zoom slider -->
      <div class="ml-2 d-flex card flex-row pl-1 pr-2">
        <input type="range" class="form-range ml-2" id="customRange1" [(ngModel)]="linearZoomScale" min="0" max="100" step="1" />
        <con-icon-by-name class="d-flex align-items-center ml-2" iconName="search-plus"></con-icon-by-name>
        <label for="customRange1" class="form-label d-flex align-items-center ml-2 mb-0">%{{ getExponentialZoomScale().toFixed(0) }}</label>
      </div>

      <!-- document style toggle -->
      <div class="ml-2 d-flex card flex-row align-items-center pl-2 pr-1">
        <con-icon-by-name class="" iconName="adjust"></con-icon-by-name>
        <label class="switch my-0">
          <input type="checkbox" [ngModel]="zoomService.stylingEnabled | async" (ngModelChange)="zoomService.stylingEnabled.next($event)" />
          <span class="slider round" style="transform: scale(0.75);"></span>
        </label>
      </div>
      <con-doc-process-counter class="ml-auto"></con-doc-process-counter>
      <con-loader-small class="mx-3" *ngIf="updatingInstanceData"></con-loader-small>
    </div>
  `,
  styles: [
    `
      :host ::ng-deep .circular-loader {
        width: 34px;
      }

      :host ::ng-deep .circular-loader-container {
        margin: 0;
      }
      :host {
        margin: 10px 0 8px -25px;
        display: block;
        padding-right: 0;
      }
    `,
  ],
})
export class AnnotateViewHeaderComponent implements OnInit {
  @Input() updatingInstanceData: boolean;
  @Output() onSaveData = new EventEmitter(); // TODO these two event emitters do the same thing, except one returns a boolean. maybe we can merge those two?
  @Output() updatingInstanceDataChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _linearZoomScale: number;

  get linearZoomScale(): number {
    return this._linearZoomScale;
  }

  set linearZoomScale(val: number) {
    this._linearZoomScale = val;
    const exponentialZoom: number = this.getExponentialZoomScale();
    this.zoomService.zoomMagnification.next(exponentialZoom);
  }

  constructor(private router: Router, public zoomService: ZoomService) {
    this._linearZoomScale = 50;
  }

  getExponentialZoomScale() {
    // exponential fit {0,25},{50,100},{100,400} to range in [25,400]
    const result = 25 * Math.exp(0.0277259 * this.linearZoomScale);
    return result;
  }

  ngOnInit(): void {}

  onGoBackButtonClick() {
    const from: string = window.location.pathname;
    this.router.navigateByUrl(UtilService.getUpperRouteUrl(from), {});
  }

  onConfirmButtonClick() {
    this.updatingInstanceDataChange.emit(true);
    this.updatingInstanceData = true;
    this.onSaveData.emit();
  }
}
