import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MaterialTableComponent } from '../../doc-process-common/components/material-table/material-table.component';
import { Task } from '../models/Task';
import { InstanceService } from '../services/instance.service';
import { SetSelectedInstanceId } from '../state-management/actions';
import { Store } from '@ngxs/store';
import { Hotkeys } from '../services/hotkeys.service';
import { Instance } from '../interfaces/instance-data';
import { FieldClass } from '../interfaces/field-color';

type instanceTableData = Pick<Instance, 'instance_id' | 'issuer_name'>;

@Component({
  selector: 'doc-process-instance-list',
  template: `
    <div class="card" id="instanceCard" style="height: 90vh; overflow-y: auto;">
      <div class="card-header">Instances</div>
      <div class="card-body p-0">
        <con-material-table
          #instanceNavigationTable
          [isClickable]="true"
          [isSelectable]="true"
          [selectableColumns]="[0]"
          [clickableColumns]="[0, 1]"
          [data]="DocProcessInstanceListComponent.getInstanceTableInfo(instanceTableData)"
          [fieldClasses]="fieldClasses"
          [columnsToHide]="['']"
          (onClick)="onInstanceSelect($event.instance_id)"
        ></con-material-table>
      </div>
    </div>
  `,
})
export class DocProcessInstanceListComponent implements AfterViewInit, OnInit {
  @Input() instanceTableData: Task;
  @Input() focusedInstanceIndex: BehaviorSubject<number>;
  @ViewChild('instanceNavigationTable') instanceNavigationTable: MaterialTableComponent<any, any>;
  public fieldClasses: FieldClass[];
  private subscription: Subscription;

  get DocProcessInstanceListComponent() {
    return DocProcessInstanceListComponent;
  }

  public static getInstanceTableInfo(instanceTableData: Task): Array<instanceTableData> {
    const instanceTableInfo = instanceTableData?.instances.map((eachInstance) => {
      const fieldExtractor = ({ instance_id, issuer_name }) => ({ instance_id, issuer_name });
      const trimmedInstance: instanceTableData = fieldExtractor(eachInstance) as instanceTableData;
      return trimmedInstance;
    });

    return instanceTableInfo;
  }

  constructor(private instanceService: InstanceService, private store: Store, public hotkeys: Hotkeys) {}

  ngOnInit(): void {
    this.fieldClasses = this.getFieldClasses();
  }

  ngAfterViewInit(): void {
    this.subscription = this.hotkeys.addShortcut({ keys: 'control.space' }).subscribe(() => {
      setTimeout(() => {
        this.markValidated();
      }, 100);
    });

    setTimeout(() => {
      this.enableInstanceCheckbox();
    }, 0);
  }

  onInstanceSelect(instanceId: number) {
    const instanceIndex = this.instanceTableData.instances.findIndex((instance) => instance.instance_id === instanceId);
    this.focusedInstanceIndex.next(instanceIndex);
    this.enableInstanceCheckbox();
    this.instanceService.setInstanceId(instanceId);
    this.store.dispatch(new SetSelectedInstanceId(instanceId));
    this.instanceService.loadInstanceDocumentsBatch(instanceId);
    this.clearFieldClasses();
    this.setFieldClass(instanceIndex, FieldClass.Current);
  }

  private enableInstanceCheckbox() {
    const newInstanceId = this.instanceTableData.instances[this.focusedInstanceIndex.getValue()].instance_id;
    this.instanceNavigationTable.selection.select(newInstanceId);
  }

  private markValidated() {
    const currentInstanceIndex = this.instanceTableData.instances.findIndex((instance) => instance.instance_id === this.instanceService.getSelectedInstanceId());
    this.setFieldClass(currentInstanceIndex, FieldClass.Validated);
    if (currentInstanceIndex + 1 < this.instanceTableData.instances.length) {
      this.onInstanceSelect(this.instanceTableData.instances[currentInstanceIndex + 1].instance_id);
    }
  }

  clearFieldClasses() {
    this.fieldClasses = this.fieldClasses.map((fieldClass) => (fieldClass === FieldClass.Current ? (fieldClass = FieldClass.Previous) : fieldClass));
  }
  setFieldClass(index, value: FieldClass) {
    if (!(this.fieldClasses[index] === FieldClass.Validated)) {
      this.fieldClasses[index] = value;
    }
  }
  getFieldClasses() {
    const val = DocProcessInstanceListComponent.getInstanceTableInfo(this.instanceTableData).map((i, index) =>
      index !== this.focusedInstanceIndex.getValue() ? FieldClass.Previous : FieldClass.Current
    );
    return val;
  }

  getSelectedInstanceIds() {
    return this.instanceNavigationTable.selection.selected;
  }
}
