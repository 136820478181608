import { DynamicDataEntryComponentTypeEnum } from '../models/dynamic-data-entry-component-type-enum';
import { DynamicDataEntryComponentOptions } from '../models/dynamic-data-entry-component-options';

export interface DynamicDataEntryComponentInterface {
  component_type: DynamicDataEntryComponentTypeEnum;
  components?: Array<DynamicDataEntryComponentInterface>;
  title?: string;
  mapping?: string;
  options?: DynamicDataEntryComponentOptions;
  enums?: Array<string>;
  url: string;
  confirmation?: boolean;
  isSelected?: boolean;
}

export enum ComponentConfirmationEnum {
  AUTO = 'Auto', // don't use it - backend hardcoded
  CHECKED = 'Checked',
  ALTERED = 'Altered',
  IGNORED = 'ignored', // don't use it - backend hardcoded
}
