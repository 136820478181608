import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { CanDeactivateComponent } from '../interfaces/can-deactivate-component';
import { CanDeactivateNotification } from './can-deactivate-notification.service';

@Injectable({ providedIn: 'root' })
export class CanDeactivateGuard implements CanDeactivate<any> {
  constructor(private canDeactivateService: CanDeactivateNotification) {}

  // return false to interrupt navigation, true to continue.
  canDeactivate(component: CanDeactivateComponent): boolean {
    if (typeof component.canDeactivate === 'function' && !component.canDeactivate()) {
      return this.canDeactivateService.canDeactivateUserConfirmation();
    }
    return true;
  }
}
