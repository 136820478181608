export interface InstanceDataKiid {
  Name: InstanceDataValue;
  MgmtCompany: InstanceDataValue;
  InvestmentMandate: InstanceDataValue;
  Benchmark: InstanceDataValue;
  SRRI: InstanceDataValue;
  PublicationDate: InstanceDataValue;
  OngoingChargesDate: InstanceDataValue;
  ShareClasses: ShareClass[];
  Language: InstanceDataValue;
  BalanceSheet: InstanceDataValue;
  CashflowStatement: InstanceDataValue;
  IncomeStatement: InstanceDataValue;
  KPI: InstanceDataValue;
  SegmentsGeo: InstanceDataValue;
  SegmentsProduct: InstanceDataValue;
  Objective: InstanceDataValue;
  InvestorTargetGroup: InstanceDataValue;
  ISIN: InstanceDataValue;
  RecHoldingPeriod: InstanceDataValue;
  BondType: InstanceDataValueFactsheet;
  Country: InstanceDataValueFactsheet;
  Currency: InstanceDataValueFactsheet;
  Duration: InstanceDataValueFactsheet;
  InvestmentType: InstanceDataValueFactsheet;
  Rating: InstanceDataValueFactsheet;
  Region: InstanceDataValueFactsheet;
  Sector: InstanceDataValueFactsheet;
  TopHoldings: InstanceDataValueFactsheet;
  DocumentType: InstanceDataValue;
}

export const FactsheetTables: string[] = ['BondType', 'Country', 'Currency', 'Duration', 'InvestmentType', 'Rating', 'Region', 'Sector', 'TopHoldings'];


export interface ShareClass {
  ISIN: InstanceDataValue;
  EntryCharge: InstanceDataValue;
  ExitCharge: InstanceDataValue;
  OngoingCharges: InstanceDataValue;
  PerformanceFee: InstanceDataValue;
}

export interface ShareClassFactsheet {
  name: string;
  value: string;
  mapping: string;
}

export interface InstanceDataValueFactsheet {
  value: Array<ShareClassFactsheet>;
  ml_conf: string;
  altered: boolean;
  confirmed: string;
}

export interface ShareClassPriip {
  CarriedInterest: InstanceDataValue;
  ISIN: InstanceDataValue;
  EntryCharge: InstanceDataValue;
  ExitCharge: InstanceDataValue;
  OtherOngoingCosts: InstanceDataValue;
  PerformanceFee: InstanceDataValue;
  TransactionCosts: InstanceDataValue;
}

export interface ShareClassKiidMetadata {
  CarriedInterest: InstanceDataValue;
  ISIN: InstanceDataValue;
  EntryCharge: InstanceDataValue;
  ExitCharge: InstanceDataValue;
  OtherOngoingCosts: InstanceDataValue;
  PerformanceFee: InstanceDataValue;
  TransactionCosts: InstanceDataValue;
  DocumentType: InstanceDataValue;
}

export interface InstanceDataValue {
  value: string;
  ml_conf: string;
  altered: boolean;
  confirmed: string;
}
export interface Instance {
  instance_id: number;
  task_order: number;
  source_id: number;
  issuer_id: number;
  issuer_name: string;
  source_domain: string;
  doc_id: number;
  document_file: string;
  document_type: string;
  document_html_converted: boolean;
  document_lang: string;
  validated: boolean;
  isin: string[];
  confirmed_count: number;
  value_count: number;
  total: number;
  show_page: number;
}

export interface TaskOverview {
  field_details: FieldDetails;
}
export interface FieldDetails {
  Name: Name;
  OngoingChargesDate: OngoingChargesDate;
  InvestmentMandate: InvestmentMandate;
  Benchmark: Benchmark;
  Language: Language;
  MgmtCompany: MgmtCompany;
  SRRI: SRRI;
  PublicationDate: PublicationDate;
  ShareClasses: ShareClasses;
}

export interface Name {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface OngoingChargesDate {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface InvestmentMandate {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface Benchmark {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface Language {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface MgmtCompany {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface SRRI {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface PublicationDate {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface OngoingCharges {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface ShareClassStatus {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface ExitCharge {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface EntryCharge {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface ShareCurrency {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface PerformanceFee {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface ISIN {
  total: number;
  confirmed_count: number;
  value_count: number;
}

export interface ShareClasses {
  OngoingCharges: OngoingCharges;
  ShareClass: ShareClass;
  ExitCharge: ExitCharge;
  EntryCharge: EntryCharge;
  ShareCurrency: ShareCurrency;
  PerformanceFee: PerformanceFee;
  ISIN: ISIN;
}

export interface InstanceDataNews {
  instance_id: number;
  doc_meta: InstanceDataNewsDocMeta;
  meta: InstanceDataNewsMeta;
  taxonomy_data: InstanceDataNewsTaxonomyData;
}

export interface InstanceDataTopicClassification {
  meta: InstanceDataNewsMeta;
  doc_meta: TopicClassificationDocMeta;
  is_duplicate: boolean;
  calendar_event_ids: Array<number>;
  taxonomy_data: { classes: Array<TopicClassificationField>; classification_type?: string };
}

export interface TopicClassificationDocMeta {
  company: TopicClassificationCompany;
}

export interface TopicClassificationCompany {
  company_id: number;
  company_name: string;
  company_code: string;
  company_tiers: Array<number>;
  company_website?: string;
}

export type TopicClassificationField = {
  field: string;
  parent_field: string;
  title: string;
  value: boolean;
  suggestion: boolean;
};

export type InstanceData = InstanceDataNews | InstanceDataKiid | InstanceDataTopicClassification;

export type InstanceDataUnioned = InstanceDataNews & InstanceDataKiid;

export interface InstanceDataNewsTaxonomyData {
  [key: string]: TaxonomyData;
}

export interface InstanceDataTopicClassificationTaxonomyData {
  classes: Array<TaxonomyData>;
}

export enum TaxonomyFieldTypes {
  orders,
  orders_growth,
  orders_growth_organic,

  revenue,
  revenue_growth,
  revenue_growth_organic,
  net_interest_income,
  net_commission_income,
  net_financial_income,
  net_other_income,
  rental_income,
  total_income,
  net_operating_income,
  yield,
  gross_profit,
  gross_margin,

  ebitda,
  ebitda_margin,
  ebitda_adjusted,
  ebitda_adjusted_margin,

  ebita,
  ebita_margin,
  ebita_adjusted,
  ebita_adjusted_margin,

  ebit,
  ebit_margin,
  ebit_adjusted,
  ebit_adjusted_margin,

  total_expenses,
  profit_before_credit_losses,
  credit_losses_net,
  operating_profit,
  result_from_property_mgmt,
  changes_in_value_total,
  changes_in_value_properties,
  changes_in_value_derivatives,
  pretax_profit,
  pretax_profit_adjusted,

  net_profit,
  net_profit_adjusted,

  net_profit_after_min,
  net_profit_after_min_adjusted,

  eps,
  eps_diluted,
  eps_adjusted,
  nav_actual,
  nav_long_term,
  epra_nrv,
  epra_nta,
  epra_ndv,
  core_tier_1_ratio,
  cost_income_ratio,
  return_on_equity,

  dividend,
  dividend_extra,
}

export enum NewsTaxonomyDataFieldColors {
  '#' = 'gray',
  orders = '#F57C00',
  orders_growth = '#FF9800',
  orders_growth_organic = '#FFB74D',

  revenue = '#689F38',
  revenue_growth = '#8BC34A',
  revenue_growth_organic = '#AED581',

  gross_profit = '#5D4037',
  gross_margin = '#A1887F',

  ebitda = '#D32F2F',
  ebitda_margin = '#E57373',
  ebitda_adjusted = '#C2185B',
  ebitda_adjusted_margin = '#F06292',

  ebita = '#303F9F',
  ebita_margin = '#7986CB',
  ebita_adjusted = '#1976D2',
  ebita_adjusted_margin = '#64B5F6',

  ebit = '#512DA8',
  ebit_adjusted = '#7B1FA2',
  ebit_adjusted_margin = '#BA68C8',
  ebit_margin = '#9575CD',

  pretax_profit = '#AFB42B',
  pretax_profit_adjusted = '#CDDC39',

  net_profit = '#0097A7',
  net_profit_adjusted = '#00BCD4',

  net_profit_after_min = '#00796B',
  net_profit_after_min_adjusted = '#009688',

  eps = '#455A64',
  eps_diluted = '#607D8B',
  eps_adjusted = '#90A4AE',

  sales_growth_organic = '#89c4f4',

  dividend = '#FFEA00',
  dividend_extra = '#FFFF8D',

  total_income = '#689F38',
  rental_income = '#303F9F',
  property_expenses = '#7986CB',
  net_operating_income = '#1976D2',
  yield = '#512DA8',
  result_from_property_management = '#64B5F6',
  result_from_property_mgmt = '#64B5F6',
  changes_in_value_total = '#F57C00',
  changes_in_value_properties = '#FF9800',
  changes_in_value_derivatives = '#FFB74D',

  net_interest_income = '#689F38',
  net_commission_income = '#303F9F',
  net_financial_income = '#7986CB',
  net_other_income = '#1976D2',
  total_expenses = '#64B5F6',
  profit_before_credit_losses = '#F57C00',
  credit_losses_net = '#FF9800',
  operating_profit = '#FFB74D',

  core_tier_1_ratio = '#E57373',
  cost_income_ratio = '#C2185B',
  return_on_equity = '#F06292',

  nav_actual = '#E57373',
  nav_long_term = '#E57373',
  epra_nrv = '#C2185B',
  epra_nta = '#F06292',
  epra_ndv = '#E57373',
}
export enum TaxonomyDataFieldIndentClasses {
  // "#"="gray",
  orders = 'main',
  orders_growth = 'second-child',
  orders_growth_organic = 'second-child',

  revenue = 'main',
  revenue_growth = 'second-child',
  revenue_growth_organic = 'second-child',

  gross_profit = 'main',
  gross_margin = 'second-child',

  ebitda = 'main',
  ebitda_margin = 'second-child',
  ebitda_adjusted = 'first-child',
  ebitda_adjusted_margin = 'second-child',

  ebita = 'main',
  ebita_margin = 'second-child',
  ebita_adjusted = 'first-child',
  ebita_adjusted_margin = 'second-child',

  ebit = 'main',
  ebit_margin = 'second-child',
  ebit_adjusted = 'first-child',
  ebit_adjusted_margin = 'second-child',

  pretax_profit = 'main',
  pretax_profit_adjusted = 'first-child',

  net_profit = 'main',
  net_profit_adjusted = 'first-child',

  net_profit_after_min = 'main',
  net_profit_after_min_adjusted = 'first-child',

  eps = 'main',
  eps_diluted = 'second-child',
  eps_adjusted = 'second-child',

  total_income = 'main',
  rental_income = 'main',
  property_expenses = 'main',
  net_operating_income = 'main',
  result_from_property_management = 'main',
  changes_in_value_total = 'main',
  changes_in_value_properties = 'main',
  changes_in_value_derivatives = 'main',

  net_interest_income = 'main',
  net_commission_income = 'main',
  net_financial_income = 'main',
  net_other_income = 'main',
  total_expenses = 'main',
  profit_before_credit_losses = 'main',
  credit_losses_net = 'main',
  operating_profit = 'main',

  dividend = 'main',
  dividend_extra = 'first-child',

  core_tier_1_ratio = 'main',
  cost_income_ratio = 'main',
  return_on_equity = 'main',

  yield = 'main',
  result_from_property_mgmt = 'main',
  nav_actual = 'main',
  nav_long_term = 'main',
  epra_nrv = 'main',
  epra_nta = 'main',
  epra_ndv = 'main',
}

export enum TaxonomyDataHotkeyBinding {
  // "#"="gray",
  orders = 'tab',
  orders_growth = 'shift',
  orders_growth_organic = 'alt',

  revenue = 'tab',
  revenue_growth = 'shift',
  revenue_growth_organic = 'alt',

  gross_profit = 'tab',
  gross_margin = 'shift',

  ebitda = 'tab',
  ebitda_margin = 'shift',
  ebitda_adjusted = 'alt',
  ebitda_adjusted_margin = 'alt',

  ebita = 'tab',
  ebita_margin = 'shift',
  ebita_adjusted = 'alt',
  ebita_adjusted_margin = 'alt',

  ebit = 'tab',
  ebit_margin = 'shift',
  ebit_adjusted = 'alt',
  ebit_adjusted_margin = 'alt',

  pretax_profit = 'tab',
  pretax_profit_adjusted = 'shift',

  net_profit = 'tab',
  net_profit_adjusted = 'shift',

  net_profit_after_min = 'shift',
  net_profit_after_min_adjusted = 'shift',

  eps = 'tab',
  eps_diluted = 'shift',
  eps_adjusted = 'alt',

  net_interest_income = 'tab',
  net_commission_income = 'tab',
  net_financial_income = 'tab',
  net_other_income = 'tab',
  total_expenses = 'tab',
  profit_before_credit_losses = 'tab',
  credit_losses_net = 'tab',
  operating_profit = 'tab',

  dividend = 'tab',
  dividend_extra = 'shift',

  total_income = 'tab',
  rental_income = 'tab',
  property_expenses = 'tab',
  net_operating_income = 'tab',
  result_from_property_management = 'tab',
  changes_in_value_total = 'tab',
  changes_in_value_properties = 'tab',
  changes_in_value_derivatives = 'tab',

  core_tier_1_ratio = 'tab',
  cost_income_ratio = 'tab',
  return_on_equity = 'tab',

  yield = 'tab',
  result_from_property_mgmt = 'tab',
  nav_actual = 'tab',
  nav_long_term = 'tab',
  epra_nrv = 'tab',
  epra_nta = 'tab',
  epra_ndv = 'tab',
}

export interface TaxonomyData {
  module_id: number;
  values: Array<TaxonomyDataValue>;
}

export interface TaxonomyDataValue {
  altered: boolean;
  confirmed: any;
  annot_id: number;
  ml_conf: string;
  period: TaxonomyContextPeriod;
  quantity: TaxonomyQuantity;
  sign: TaxonomySign;
  conf: number;
  value: string;
  values: Array<TaxonomyDataValue>;
}

export interface InstanceDataNewsDocMeta {
  document_type: DocMetaDocumentType;
  report_date_end: string;
  fiscal_period: string;
  currency: DocMetaCurrency;
}

export enum DocMetaDocumentType {
  EarningsRelease,
  TradingStatement,
  Other,
}

export enum DocMetaCurrency {
  USD,
  EUR,
  GBP,
  NOK,
  SEK,
  DKK,
  CHF,
  CAD,
  CZK,
  TRY,
  RUB,
  LTL,
}

export interface InstanceDataNewsMeta {
  taxonomy: string;
}

export enum TaxonomyContextPeriod {
  ThreeMonth = '3M',
  SixMonths = '6M',
  NineMonth = '9M',
  FY = 'FY',
  FYOut = 'FY_out',
}

export enum TaxonomyQuantity {
  Billion = 'B',
  Million = 'M',
  Thousand = 'T',
  R = 'r',
  C = 'c',
  Percent = '%',
}

export enum TaxonomySign {
  Plus = 'p',
  Minus = 'n',
}
export interface TaxonomyDataSpan {
  xref: string;
  offsets: Array<number>;
  text: string;
}

export const LANGUAGES: Array<string> = ['EN', 'DE', 'FR', 'NL', 'IT', 'ES', 'NB', 'SV', 'DA', 'FI', 'IS'];
