import { Component, Input } from '@angular/core';

@Component({
  selector: 'con-dynamic-confirmation-icon',
  template: `
    <con-icon-by-name *ngIf="isConfirmed" class="success" [ngClass]="{ light: light }" iconName="check-circle-o"></con-icon-by-name>
    <con-icon-by-name *ngIf="!isConfirmed" [ngClass]="{ transparent: showEmpty, light: light }" class="warning" iconName="exclamation-triangle"></con-icon-by-name>
  `,
  styles: [':host {float: left} .success {color: green} .warning { color: orange} .transparent {opacity: 0} .light { color: white!important;}'],
})
export class DynamicConfirmationIconComponent {
  @Input() isConfirmed: boolean;
  @Input() showEmpty?: boolean;
  @Input() light: boolean;
}
