import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DocumentService } from '../../doc-process-common/services/document.service';
import { Observable, of, Subject } from 'rxjs';
import { FundamentalsStatementService } from '../services/fundamentals-statement.service';
import TextHighlighter from 'texthighlighter';
import { HighlightEvent } from '../../doc-process-common/models/highlight-event';
import { HighlightEventType } from '../../doc-process-common/models/highlight-event-type';
import Mark from 'mark.js';
import { DocProcessHandlerUtilsService } from '../../doc-process-common/services/utils.service';
import { NewsInstancesService } from '../../doc-process-common/services/news-instances.service';
import { UtilService } from '../../doc-process-common/services/util.service';
import { MarkModel } from '../../doc-process-common/models/mark-model';
import { IdentifierModel } from '../../doc-process-common/models/identifier-model';
import { take, takeUntil } from 'rxjs/operators';
import { ZoomService } from '../../doc-process-common/services/zoom.service';
import { InstanceService } from '../services/instance.service';
import { InstanceDefinition } from '../interfaces/fields';

@Component({
  selector: 'doc-process-annotate-doc-view',
  styleUrls: ['styles/doc-process-annotation-view.component.scss'],
  template: `
    <div class="card" style="min-height: 90vh; max-height: 90vh; overflow-y: scroll;">
      <section #documentContents class="content" style="padding: 12px;" (click)="onClickEvent($event)"></section>
    </div>
  `,
})
export class DocProcessAnnotateDocViewComponent implements OnDestroy, OnChanges, AfterViewInit {
  @Input() instanceDocument: any;
  @Input() fundamentalsStatementService: FundamentalsStatementService;
  @ViewChild('documentContents', { static: false }) private documentContents: ElementRef;
  private subscribeUntil: Subject<boolean> = new Subject();
  private domMarkElements: Array<{ mark: Mark; node: HTMLElement }> = new Array<{ mark: Mark; node: HTMLElement }>();
  private ranAfterViewInit = false;
  latestScrollPosition: string;
  constructor(private pdfService: DocumentService, private instanceService: InstanceService, private fundamentalsZoomService: ZoomService) {}

  ngAfterViewInit() {
    this.ranAfterViewInit = true;

    UtilService.logIfAdmin('DocProcessAnnotateDocViewComponent ngAfterViewInit entered');

    this.loadDocument();

    const documentContainerElement = this.getDocumentContentContainer();
    this.attachHighlightListener(documentContainerElement);

    this.fundamentalsStatementService?.documentMarks.pipe(takeUntil(this.subscribeUntil)).subscribe((documentMarks: MarkModel[]) => {
      this.onAnnotationsUpdate(documentMarks);
    });

    this.updateZoom(this.fundamentalsZoomService.zoomMagnification.getValue());
  }

  ngOnChanges(changes) {
    this.subscribeUntil.next(true);
    if (changes?.instanceDocument?.currentValue) this.loadDocument();
    this.fundamentalsZoomService.zoomMagnification.pipe(takeUntil(this.subscribeUntil)).subscribe((zoomMag: number) => {
      this.updateZoom(zoomMag);
    });

    this.fundamentalsZoomService.stylingEnabled.pipe(takeUntil(this.subscribeUntil)).subscribe((stylingEnabled: boolean) => {
      this.loadDocument();
    });

    if (!this.fundamentalsStatementService) return;

    this.fundamentalsStatementService.scrollToMark.pipe(takeUntil(this.subscribeUntil)).subscribe((focusedMarkId: IdentifierModel) => {
      this.scrollToMark(focusedMarkId);
    });

    this.fundamentalsStatementService.focusedMarkId.pipe(takeUntil(this.subscribeUntil)).subscribe((focusedMarkId: IdentifierModel) => {
      this.outlineHighlightFromMarkId(focusedMarkId);
      const focusedMarkElement = this.findMarkElementFromMarkId(focusedMarkId);
      if (focusedMarkElement) this.latestScrollPosition = NewsInstancesService.getXpathRelativeToDocumentRoot(DocProcessHandlerUtilsService.getXpath(focusedMarkElement.node.parentElement));
    });

    if (this.ranAfterViewInit) this.ngAfterViewInit();
  }

  ngOnDestroy() {
    this.subscribeUntil.next(true);
  }

  private onAnnotationsUpdate(documentMarks: MarkModel[]) {
    UtilService.logIfAdmin('onAnnotationsUpdate');
    this.removeAllMarks();

    const focusedMarkId = this.fundamentalsStatementService?.focusedMarkId.getValue();

    documentMarks?.forEach((mark: MarkModel) => {
      this.createMarkOnDocument(mark, focusedMarkId?.toString() === mark?.id?.toString());
    });
  }

  private createMarkOnDocument(mark: MarkModel, focused = false) {
    const pdfRootXpath = DocProcessHandlerUtilsService.getXpath(this.documentContents.nativeElement);
    const markRelativePath = mark.range.rangeOffset.relativeXpath;
    const parentElement = DocProcessHandlerUtilsService.getElementByXpath(markRelativePath, pdfRootXpath);

    const rangeOffsetFromParentElement = mark.range.rangeOffset;

    const marker = new Mark(parentElement);
    const options = {
      element: 'mark',
      className: 'highlight',
      each: (node: HTMLElement, range) => {
        // if (range.start === 0 && range.length === 1) return;

        this.domMarkElements.push({ mark: marker, node: node });
        node.addEventListener('click', (ev: MouseEvent) => {
          this.onHighlightClick(node);
        });

        node.setAttribute('data-mark-id', JSON.stringify(mark.id));
        node.setAttribute('data-before', mark.getFieldNames());
        node.style.backgroundColor = mark.getColor();
        node.style.whiteSpace = 'nowrap';
        node.style.padding = '0.1em';
        node.style.paddingLeft = '0.3em';
        node.style.paddingRight = '0.3em';

        if (focused) node.classList.add('outline');

        if (mark.sign == -1) node.classList.add('negative');
      },
      noMatch: (onNoMatch) => {
        UtilService.logIfAdmin('onMarkJsNoMatch');
        UtilService.logIfAdmin(onNoMatch);
      },
      done: (onDone) => {},
    };
    marker.markRanges(
      [
        {
          start: rangeOffsetFromParentElement.start,
          length: rangeOffsetFromParentElement.length,
        },
      ],
      options
    );
  }

  onHighlightClick(node: HTMLElement) {
    UtilService.logIfAdmin('onHighlightClick');
    UtilService.logIfAdmin(node);
    const markId = FundamentalsStatementService.getMarkObjectFromElement(node);
    this.fundamentalsStatementService.focusedMarkId.next(markId);
    this.outlineHighlight(node);

    this.fundamentalsStatementService.onMarkClick.next(markId);
  }

  private outlineHighlightFromMarkId(markId: IdentifierModel): void {
    const markElementToHighlight = this.domMarkElements.find(
      (mark: { mark: Mark; node: HTMLElement }) => FundamentalsStatementService.getMarkObjectFromElement(mark.node).toString() === markId?.toString()
    );
    // const markIdToHighlight = markableDocumentService.getMarkObjectFromElement(markElementToHighlight.node)
    if (markElementToHighlight) this.outlineHighlight(markElementToHighlight.node);
    else this.clearFocusFromAllMarkElements();
  }

  private outlineHighlight(node: HTMLElement) {
    // TODO Instead, we can add an attribute to MarkModal and update the value in
    // service, then trigger a document marks update.
    this.clearFocusFromAllMarkElements();
    node.classList.add('outline');
  }

  private clearFocusFromAllMarkElements() {
    this.domMarkElements.forEach((mark) => {
      mark.node.classList.remove('outline');
    });
  }

  private attachHighlightListener(documentContainerElement: Element) {
    new TextHighlighter(documentContainerElement, {
      onBeforeHighlight: (range: Range) => {
        this.raiseHighlightEventFromRange(range);
        return false;
      },
    });
  }

  getRawDocument(): Observable<string> {
    const instanceDefinition = {
      InstanceDocument: {
        doc_content: this.instanceDocument?.doc_content,
      },
    } as InstanceDefinition;
    if (!instanceDefinition.InstanceDocument.doc_content) return of('');
    const rawDocument = of(this.pdfService.getDocumentString(instanceDefinition, !this.isStyleEnabled()));
    return rawDocument;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyboardEvent(ev: KeyboardEvent) {
    if (this.fundamentalsStatementService) {
      if (document.activeElement.tagName.toLowerCase() === 'input') return;

      if (ev.key === 'Delete' || ev.key === 'Backspace') {
        UtilService.logIfAdmin('on press delete');
        const markToDelete: IdentifierModel = this.fundamentalsStatementService.focusedMarkId.getValue();
        this.removeMarkByGuid(markToDelete);
      }
      if (ev.key.toLowerCase() === 's' || ev.key === '-') {
        UtilService.logIfAdmin('on press sign hotkey');
        this.fundamentalsStatementService.switchSignOfSelectedMark();
      }
    }
  }

  private getDocumentContentContainer() {
    return this.documentContents.nativeElement;
  }

  private removeMarkByGuid(markIdToRemove: IdentifierModel) {
    this.fundamentalsStatementService.removeMark(markIdToRemove);
  }

  private removeAllMarks() {
    for (let markIndex = 0; markIndex < this.domMarkElements.length; markIndex++) {
      this.domMarkElements[markIndex].mark.unmark();
    }
    this.domMarkElements = [];
  }

  private updateZoom(zoomMagnification: number): void {
    const element = this.documentContents?.nativeElement;
    if (!element) return;

    new UtilService().setZoom(zoomMagnification / 100, element);
    this.scrollToEntryPoint();
  }

  private loadDocument() {
    this.getRawDocument()
      .pipe(take(1))
      .subscribe((doc) => {
        this.loadDocumentHtmlContents(doc);

        setTimeout(() => {
          const documentMarks = this.fundamentalsStatementService?.documentMarks.getValue();
          this.onAnnotationsUpdate(documentMarks);
          setTimeout(() => {
            this.scrollToEntryPoint();
          }, 0);
        }, 100);
      });
  }

  private loadDocumentHtmlContents(doc: string) {
    const nativeElement = this.documentContents?.nativeElement;

    if (!!nativeElement && !!doc && nativeElement.innerHTML !== doc) this.documentContents.nativeElement.innerHTML = doc;
  }

  scrollToEntryPoint() {
    const nodeToScroll = this.domMarkElements?.[0]?.node;
    if (nodeToScroll) {
      DocProcessAnnotateDocViewComponent.scrollToElement(nodeToScroll);
      this.latestScrollPosition = NewsInstancesService.getXpathRelativeToDocumentRoot(DocProcessHandlerUtilsService.getXpath(nodeToScroll.parentNode));
    } else if (this.latestScrollPosition) {
      const elementToScroll = DocProcessHandlerUtilsService.getElementByXpath(this.latestScrollPosition, DocProcessHandlerUtilsService.getXpath(this.documentContents.nativeElement));
      DocProcessAnnotateDocViewComponent.scrollToElement(elementToScroll as HTMLElement);
    }
  }

  static scrollToElement(element: HTMLElement) {
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'end',
      });
    }
  }

  private raiseHighlightEventFromRange(range: Range) {
    if (!range || range.endOffset - range.startOffset <= 0) return false;
    if (this.fundamentalsStatementService) {
      //TODO: not a best solution if we refactor fundamentalsStatementService one day
      const highlightEvent = new HighlightEvent(HighlightEventType.CreateHighlight, range);
      this.fundamentalsStatementService.markEventStream.next(highlightEvent);
    }
  }

  public onClickEvent($event: MouseEvent) {
    if (this.isElementClickHighlightingModeActive($event) === false) return;

    const range = UtilService.getRangeFromHtmlElement($event.target as HTMLElement);

    this.raiseHighlightEventFromRange(range);
  }

  private isElementClickHighlightingModeActive(ev: MouseEvent) {
    if (!ev.getModifierState('CapsLock') || ev.altKey || ev.ctrlKey || ev.metaKey || ev.shiftKey) return false;
    return true;
  }

  private isStyleEnabled() {
    return this.fundamentalsZoomService.stylingEnabled.getValue();
  }

  private scrollToMark(focusedMarkId: IdentifierModel) {
    const markElement = this.findMarkElementFromMarkId(focusedMarkId)?.node;
    if (markElement) DocProcessAnnotateDocViewComponent.scrollToElement(markElement);
  }

  private findMarkElementFromMarkId(focusedMarkId: IdentifierModel): { mark: Mark; node: HTMLElement } {
    const focusedMarkElement = this.domMarkElements.find((domMarkElement: { mark: Mark; node: HTMLElement }) => {
      const markId = FundamentalsStatementService.getMarkObjectFromElement(domMarkElement.node);
      return markId.id.toString() === focusedMarkId?.toString();
    });
    return focusedMarkElement;
  }
}
