import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedProjectService {
  private readonly _project = new BehaviorSubject<DocProcessProjectName>(null);
  private readonly _projectsList: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([])

  public readonly projectsData$: Observable<Array<any>> = this._projectsList.asObservable()

  constructor() { }

  get projectsList(): Array<any> {
    return this._projectsList.getValue()
  }

  set projectsList(data) {
    this._projectsList.next(data)
  }

  get project(): DocProcessProjectName {
    return this._project.getValue();
  }

  set project(val: DocProcessProjectName) {
    this._project.next(val);
  }

  private addProject(project: DocProcessProjectName) {
    this.project = project
  }

  public updateProjectUsingWindow(window: Window) {
    const projectId = window.location.pathname.split('/')[4]
    const viewType = (this.projectsList as any[]).filter(item => item.project_id.toString() === projectId)[0]
    this.addProject(viewType && viewType.view_type)
  }


  //TODO: this should be removed
  public isProjectTypeKiidLike(): boolean {
    return this.project === DocProcessProjectName.Kiid || this.project === DocProcessProjectName.KiidPriip  || this.project === DocProcessProjectName.KiidMetadata  || this.project === DocProcessProjectName.Factsheet
  }

  public isProjectTypeAnnotation(): boolean {
    return !(this.isProjectTypeKiidLike() || this.project === DocProcessProjectName.TopicClassification || this.project === DocProcessProjectName.Calendar || this.project === DocProcessProjectName.IncomingReports);
  }
}

export enum DocProcessProjectName {
    FundamentalsPageLabeling = "FdPageLabeling_v1",
    Kiid = "KIID_v1",
    KiidPriip = "KIID_PRIIP_v1",
    KiidMetadata = "FundsMetadata_v1",
    PrNewsLabeling = "NewsLabeling_v1",
    TopicClassification = "Classification_v1",
    Factsheet = "Factsheet_v1",
    Calendar = "Calendar_v1",
    PrNewsLabelingV2 = "NewsLabeling_v2",
    IncomingReports = "IncomingReports_v1",
}
