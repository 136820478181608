import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { AnnotationModel } from '../../doc-process-common/models/annotation-model';
import { SignEnum } from '../../doc-process-common/models/sign-enum';
import { MarkModel } from '../../doc-process-common/models/mark-model';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { UtilService } from '../../doc-process-common/services/util.service';
import { FundamentalsStatementService } from '../services/fundamentals-statement.service';
import { IdentifierModel } from '../../doc-process-common/models/identifier-model';
import { LabelingHelperService } from '../services/labeling-helper.service';

@Component({
  selector: 'con-field-annotation',
  template: `
    <div class="{{ !labelingHelperService.hasIndividualQuantifiers() ? 'col-6' : 'col-4' }}">
      <con-icon-by-name [hidden]="!isAnnotationSelected" [iconName]="'long-arrow-right'" class="text-info ml-4"></con-icon-by-name>
    </div>

    <div class="text-right text-monospace {{ !labelingHelperService.hasIndividualQuantifiers() ? 'col-4' : 'col-3' }}">
      <con-icon-by-name
        *ngIf="labelingHelperService.hasSanityCheck() && annotation.passedSanityCheck === false"
        [iconName]="'warning'"
        class="text-warning ml-4"
        ngbPopover="There is an inconsistency between the amounts (tagged values and quantities) of different annotations for this field."
        container="body"
        [openDelay]="300"
        [closeDelay]="500"
        triggers="mouseenter:mouseleave"
      >
        ></con-icon-by-name
      >
      {{ innerText$ | async }}
    </div>

    <div class="col-2">
      <button class="btn p-0 m-0 mr-1 text-muted" style="font-size: 0.6rem;" (click)="toggleSign()">
        <con-icon-by-name class="align-middle" [iconName]="(sign | async) === SignEnum.Negative ? 'minus' : 'plus'"></con-icon-by-name>
      </button>
      <input
        *ngIf="isConsolidationOperationCheckboxDisplayed"
        class="align-middle"
        type="checkbox"
        [checked]="annotation?.subtract"
        (change)="toggleConsolidationOperation()"
        ngbPopover="Tick this box if you want this value to be subtracted (instead of being added) during the consolidation of the module annotations. This is different than the sign of the annotation."
        container="body"
        [openDelay]="300"
        [closeDelay]="0"
        triggers="mouseenter:mouseleave"
      />
    </div>
    <div class="btn-group btn-group-sm col-3 px-0" *ngIf="labelingHelperService.hasIndividualQuantifiers()" (click)="$event.stopPropagation()">
      <button disabled class="btn btn-secondary btn-sm py-0" *ngIf="annotation?.quantity as quantity">{{ quantity?.name }} ({{ quantity?.symbol }})</button>
      <button type="button" class="btn btn-info btn-sm py-0" (click)="isQuantitySelectorHidden = !isQuantitySelectorHidden" [hidden]="!annotation?.quantity">
        <span aria-hidden="true">
          <con-icon-by-name iconName="edit"></con-icon-by-name>
        </span>
      </button>
    </div>
    <con-entity-search-field
      *ngIf="labelingHelperService.hasIndividualQuantifiers()"
      [hidden]="isQuantitySelectorHidden && !!annotation?.quantity"
      [entityName]="'Quantity'"
      [showDismiss]="false"
      (click)="$event.stopPropagation()"
      (onSelect)="onQuantitySelect($event)"
    >
    </con-entity-search-field>
  `,
})
export class FieldAnnotationComponent implements OnInit, OnDestroy {
  @Input() annotation: AnnotationModel;
  @Input() isConsolidationOperationCheckboxDisplayed: boolean;
  public sign: Observable<SignEnum>;
  public innerText$: Observable<string>;
  public mark: Observable<MarkModel>;
  private subscribeUntil: Subject<boolean> = new Subject();
  public isAnnotationSelected = false;
  public isQuantitySelectorHidden = true;

  get SignEnum() {
    return SignEnum;
  }

  constructor(private fundamentalsStatementService: FundamentalsStatementService, public labelingHelperService: LabelingHelperService) {}

  ngOnInit(): void {
    UtilService.logIfAdmin('FieldAnnotationComponent ngOnInit this.annotation:');
    UtilService.logIfAdmin(this.annotation);

    this.mark = this.fundamentalsStatementService.getMarkOfAnnotation(this.annotation.id);

    this.sign = this.mark.pipe(
      map((mark: MarkModel) => {
        return mark?.sign ?? SignEnum.Negative;
      })
    );

    this.innerText$ = this.mark.pipe(map((mark: MarkModel) => mark?.innerText ?? 'innerText'));

    combineLatest([this.fundamentalsStatementService.focusedMarkId, this.mark])
      .pipe(takeUntil(this.subscribeUntil))
      .subscribe(([focusedMarkId, thisMark]: [IdentifierModel, MarkModel]) => {
        // TODO maybe make this a pipe instead?
        if (focusedMarkId?.toString() === thisMark?.id?.toString()) this.isAnnotationSelected = true;
        else this.isAnnotationSelected = false;
      });
  }

  public onQuantitySelect($event): void {
    UtilService.logIfAdmin('onQuantitySelect($event):');
    UtilService.logIfAdmin($event);
    this.annotation.module.quantity = $event;
    this.annotation.quantity = $event;
    this.fundamentalsStatementService.annotations.next(this.fundamentalsStatementService.annotations.getValue()); // this is a gem. I had to trigger subject push after updating annotation :)
    // this.fundamentalsStatementService.documentQuantity.next($event);
    this.isQuantitySelectorHidden = true;
  }

  toggleSign() {
    this.fundamentalsStatementService.switchMarkSign(this.mark);
  }

  public toggleConsolidationOperation(): void {
    this.annotation.subtract = !this.annotation.subtract;
    this.fundamentalsStatementService.updateAnnotation(this.annotation);
  }

  ngOnDestroy() {
    this.subscribeUntil.next(true);
  }

  @HostListener('click', ['$event']) onClick($event) {
    UtilService.logIfAdmin('on sidebar annotation click:');
    UtilService.logIfAdmin(this.annotation);
    $event.stopPropagation();
    this.mark.pipe(take(1)).subscribe((mark: MarkModel) => {
      this.fundamentalsStatementService.focusedMarkId.next(mark.id);
      this.fundamentalsStatementService.onMarkClick.next(mark.id);
      this.fundamentalsStatementService.scrollToMark.next(mark.id);
    });
  }
}
