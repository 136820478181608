import { Component, Input, OnInit } from '@angular/core';
import { DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { InstanceService } from '../services/instance.service';
import { ComponentService } from '../services/component.service';
import { DynamicDataEntryAbstractInput } from './dynamic-data-entry-abstract-input.component';
import { Hotkeys } from '../services/hotkeys.service';

@Component({
  selector: 'con-dynamic-data-entry-textarea-input',
  template: `
    <con-dynamic-confirmation-icon *ngIf="isConfirmable" [isConfirmed]="this.isConfirmed" [showEmpty]="this.showEmpty"></con-dynamic-confirmation-icon>
    <div class="form-group">
      <textarea
        [ngStyle]="{ 'font-size.rem': component.options.font_size }"
        class="form-control smaller-text-form w-100"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        rows="{{ component.options.nu_rows }}"
        f
        [ngModel]="componentData.value"
        (ngModelChange)="updateValue($event)"
        name="text"
      ></textarea>
    </div>
  `,
  styles: ['textarea{line-height: 1.4}'],
})
export class DynamicDataEntryTextareaInputComponent extends DynamicDataEntryAbstractInput {
  @Input() component: DynamicDataEntryComponentInterface;
  public value$: Observable<any>;

  constructor(store: Store, instanceService: InstanceService, componentService: ComponentService, hotkeys: Hotkeys) {
    super(store, instanceService, componentService, hotkeys);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  updateValue($event: any) {
    this.componentValueToStore($event);
  }
}
