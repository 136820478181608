import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { TaskDataEntryState } from '../state-management/states';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { InstanceService } from '../services/instance.service';
import { Observable } from 'rxjs';
import { ReportStatement } from '../models/report-statement';
import { FundamentalsStatementService } from '../services/fundamentals-statement.service';
import { LabelingHelperService } from '../services/labeling-helper.service';
import { FundamentalsAnnotation } from '../interfaces/fields';

@Component({
  selector: 'con-dynamic-data-entry-labeling-input',
  providers: [LabelingHelperService, FundamentalsStatementService],
  template: `
    <con-labeling-taxonomy
      [isFundamentalsTaxonomy]="component.options.individual_quantifiers === false"
      [isDeltaAvailable]="labelingComponentOptionsService.isDeltaDisplayed$ | async"
      [reportStatement]="value$ | async"
      [instanceJsonAnnotations]="jsonAnnotations$ | async"
    ></con-labeling-taxonomy>
  `,
})
export class DynamicDataEntryLabelingInputComponent implements OnInit, OnChanges {
  @Input() component: DynamicDataEntryComponentInterface;
  @Input() isComponentTabActive: boolean;

  public value$: Observable<ReportStatement>;
  public jsonAnnotations$: Observable<Array<FundamentalsAnnotation>>;

  constructor(
    private store: Store,
    public instanceService: InstanceService,
    public fundamentalsStatementService: FundamentalsStatementService,
    public labelingComponentOptionsService: LabelingHelperService
  ) {}

  ngOnInit(): void {
    this.value$ = this.store.select(TaskDataEntryState.selectComponentData(this.instanceService.getInstanceId(), this.component)).pipe(
      map((value: any) => {
        return value;
      })
    );

    this.jsonAnnotations$ = this.store.select(TaskDataEntryState.selectInstanceJsonAnnotations(this.instanceService.getInstanceId(), this.component)).pipe(
      map((value: any) => {
        return value;
      })
    );

    this.value$.pipe(take(1)).subscribe((reportStatement) => {
      this.fundamentalsStatementService.statement.next(reportStatement);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isComponentTabActive?.currentValue === true) this.instanceService.activeFundamentalsStatementService.next(this.fundamentalsStatementService);
    if (changes?.isComponentTabActive?.currentValue === false && this.instanceService.activeFundamentalsStatementService.getValue() === this.fundamentalsStatementService)
      this.instanceService.activeFundamentalsStatementService.next(null);
  }
}
