import { Component, Input, OnInit } from '@angular/core';
import { AnnotationModel } from '../../doc-process-common/models/annotation-model';
import { UtilService } from '../../doc-process-common/services/util.service';

@Component({
  selector: 'con-field-annotations-list',
  template: `
    <div class="row row-striped" *ngFor="let annotation of annotationList; trackBy: identify">
      <con-field-annotation [annotation]="annotation" [isConsolidationOperationCheckboxDisplayed]="isConsolidationOperationCheckboxDisplayed(annotationList)" style="display: contents">
      </con-field-annotation>
    </div>
  `,
  styles: [
    `
      .row-striped:not(:last-child) {
        border-bottom: 1px solid #e0e0e0 !important;
      }
      .row-striped:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.035);
      }
      .row-striped:hover {
        background-color: rgba(0, 0, 0, 0.075);
        cursor: pointer;
      }
    `,
  ],
})
export class FieldAnnotationsListComponent implements OnInit {
  @Input() annotationList: Array<AnnotationModel>;

  get FieldAnnotationsListComponent() {
    return FieldAnnotationsListComponent;
  }

  constructor() {}

  ngOnInit(): void {}

  public identify(index, item) {
    return item.id;
  }

  public isConsolidationOperationCheckboxDisplayed(annotationList: Array<AnnotationModel>): boolean {
    // noinspection RedundantIfStatementJS
    if (annotationList.length >= 2) return true;
    else return false;
  }
}
