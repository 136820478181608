import { TaxonomyModule } from '../../doc-process-common/models/taxonomy-module';
import { Quantity } from './quantity';

export class ReportStatement {
  public company_report_id: number;
  public created_at: string;
  public created_by: number;
  public deleted_at: any;
  public deleted_by: any;
  public from_date?: string;
  public balance_sheet_date?: string;
  public snapshot_date?: string;
  public id: number;
  public modules: Array<TaxonomyModule>;
  public quantity: Quantity;
  public is_fully_annotated: boolean | null;
  public quantity_id: number;
  public to_date?: string;
  public unit: any;
  public unit_id: number;
  public updated_at: string;
  public updated_by: number;
  public is_unavailable: boolean;
  public is_visited: boolean;

  public static sortedModules(reportStatement: ReportStatement): Array<TaxonomyModule> {
    return reportStatement.modules.sort(TaxonomyModule.compare);
  }

  public static findModule(thisStatement: ReportStatement, uniqueId: string): TaxonomyModule | null {
    for (const childModule of thisStatement.modules) {
      const moduleObj = childModule;

      const targetModule = TaxonomyModule.findModuleById(moduleObj, uniqueId);
      if (targetModule) return targetModule;
    }
    return null;
  }
}
