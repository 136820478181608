import { Component, Input } from '@angular/core';

@Component({
  selector: 'con-multiclass-taxonomy-tags',
  template: `
    <ng-container *ngFor="let field of taxonomyClasses; let fieldIndex = index">
      <span *ngIf="field.value" class="btn btn-sm py-1 px-1 mb-1 btn-info">{{ field.title }}</span>
    </ng-container>
  `,
  styles: [
    `
      .btn {
        pointer-events: none;
      }
    `,
  ],
})
export class MulticlassTaxonomyTagsComponent {
  @Input() taxonomyClasses: Array<any>;
}
