import { Component, Input, OnInit } from '@angular/core';
import { EntitiesService } from '../../../../entities/services/entities.service';
import { Observable } from 'rxjs';
import { ApiService } from '../../doc-process-common/services/api.service';
import { Query } from '../../doc-process-common/services/methods.service';
import { DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { TaskDataEntryState } from '../state-management/states';
import { InstanceService } from '../services/instance.service';
import { Store } from '@ngxs/store';
import { SetComponentData } from '../state-management/actions';
import { InstanceDataTopicClassification } from '../interfaces/instance-data';

@Component({
  selector: 'con-calendar-events-widget',
  template: `
    <ng-content></ng-content>
    <con-multiclass-taxonomy-tags [taxonomyClasses]="taxonomyClasses"></con-multiclass-taxonomy-tags>
    <con-loader *ngIf="!companyEvents"></con-loader>
    <ng-container *ngIf="companyEvents && company">
      <con-dp-company-events-table
        [companyId]="companyId"
        [companyEvents]="companyEvents"
        [calendarEventIds]="calendarEventIds"
        (onSelectionChange)="handleSelectionChange($event)"
      ></con-dp-company-events-table>
      <con-dp-new-event-form [company]="company" [ownerType]="'Company'"></con-dp-new-event-form>
    </ng-container>
  `,
})
export class CalenderEventsWidgetComponent implements OnInit {
  @Input() component: DynamicDataEntryComponentInterface;
  public value$: Observable<InstanceDataTopicClassification>;
  public companyId: number;
  public calendarEventIds: Array<number>;
  public company: any;
  public companyEvents: unknown;
  public taxonomyClasses: unknown;

  constructor(private entitiesService: EntitiesService, private apiService: ApiService, private instanceService: InstanceService, private store: Store) {}

  ngOnInit(): void {
    this.value$ = this.store.select(TaskDataEntryState.selectInstanceDetails(this.instanceService.getInstanceId())) as Observable<InstanceDataTopicClassification>;
    this.value$.subscribe((data: InstanceDataTopicClassification) => {
      this.taxonomyClasses = data.taxonomy_data.classes;
      this.calendarEventIds = data.calendar_event_ids;
      this.companyId = data.doc_meta?.company?.company_id;
      this.entitiesService.getEntityById('Company', this.companyId).subscribe((company) => (this.company = company));
      this.apiService.httpGet({ params: this.companyId }, Query.GetCalendarEvents).subscribe((companyEvents) => (this.companyEvents = companyEvents));
    });
  }

  handleSelectionChange($event: Array<number>): void {
    this.store.dispatch(new SetComponentData(this.instanceService.getInstanceId(), this.component, $event, ['calendar_event_ids']));
  }
}
