import { Component, Input, OnInit } from '@angular/core';
import { DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { Observable } from 'rxjs';
import { TaskDataEntryState } from '../state-management/states';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { InstanceService } from '../services/instance.service';
import { SetComponentData } from '../state-management/actions';
import { ComponentService } from '../services/component.service';
import { DynamicDataEntryAbstractInput } from './dynamic-data-entry-abstract-input.component';
import { Hotkeys } from '../services/hotkeys.service';

@Component({
  selector: 'con-dynamic-tab-component',
  template: `
    <a ngbNavLink class="btn btn-sm btn-primary p-1 py-2" [ngClass]="{ selected: isSelected }">
      <con-dynamic-confirmation-icon *ngIf="isConfirmable" [showEmpty]="this.showEmpty" [isConfirmed]="componentData.confirmed" [light]="true"></con-dynamic-confirmation-icon>
      &nbsp;{{ component.title }}
    </a>
  `,
  styles: [
    `
      .selected {
        background-color: #009688;
      }
      .selected:not(:disabled):not(.disabled).active {
        background-color: #00635a;
        border-color: #00635a;
      }
      con-dynamic-confirmation-icon {
        float: none;
      }
    `,
  ],
})
export class DynamicTabComponent extends DynamicDataEntryAbstractInput {
  @Input() component: DynamicDataEntryComponentInterface;
  @Input() isSelected: boolean;

  constructor(store: Store, instanceService: InstanceService, componentService: ComponentService, hotkeys: Hotkeys) {
    super(store, instanceService, componentService, hotkeys);
  }

  ngOnInit() {
    super.ngOnInit();
    this.component.isSelected = this.isSelected;
  }
}
