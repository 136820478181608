import { ChangeDetectorRef, Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ReportStatement } from '../models/report-statement';
import { Subject } from 'rxjs';
import { FundamentalsStatementService } from '../services/fundamentals-statement.service';
import { InstanceService } from '../services/instance.service';
import { UtilService } from '../../doc-process-common/services/util.service';
import { DocProcessService } from '../../doc-process-common/services/doc-process.service';
import { DecimalSeparator } from '../../doc-process-common/models/decimal-separator';
import { LabelingHelperService } from '../services/labeling-helper.service';
import { Quantity } from '../models/quantity';
import { DynamicClearDataModalComponent } from './dynamic-clear-data-modal.component';
import { LabelingTaxonomyToggleComponent } from './labeling-taxonomy-toggle.component';
import { FundamentalsAnnotation } from '../interfaces/fields';

@Component({
  selector: 'con-labeling-taxonomy',
  styles: [
    `
      .statement-buttons > div > div.col > div.row > label {
        margin-left: auto !important;
        margin-right: auto !important;
        white-space: nowrap;
      }

      .statement-buttons > div > div.col > div.row > div {
        margin-left: auto !important;
        margin-right: auto !important;
      }

      .col.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    `,
  ],
  template: `
    <div *ngIf="!!reportStatement" class="container statement-buttons">
      <div class="d-flex flex-wrap">
        <con-labeling-taxonomy-toggle class="col" #isUnavailableToggle [model]="isUnavailable" [name]="'Unavailable'" (toggle)="onUnavailableClick($event)"> </con-labeling-taxonomy-toggle>

        <div class="col" *ngIf="isFundamentalsTaxonomy" [ngClass]="{ disabled: isUnavailable }">
          <div class="row">
            <label><small>Quantity</small></label>
          </div>
          <div class="row">
            <div class="btn-group btn-group-sm">
              <button disabled class="btn btn-secondary btn-sm py-0 px-1" *ngIf="quantity as quantity">
                <small>{{ quantity?.name }}</small>
              </button>
              <button type="button" class="btn btn-info btn-sm py-0 px-1" (click)="isQuantitySelectorHidden = !isQuantitySelectorHidden" [hidden]="quantity === null">
                <span aria-hidden="true">
                  <con-icon-by-name iconName="edit"></con-icon-by-name>
                </span>
              </button>
            </div>
          </div>
        </div>
        <con-labeling-taxonomy-toggle class="col" [model]="isFullyAnnotated" [name]="'Complete'" [isDisabled]="isUnavailable" (toggle)="onFullyAnnotatedClick()"> </con-labeling-taxonomy-toggle>

        <div class="col" [ngClass]="{ disabled: isUnavailable }">
          <div class="row">
            <label><small>Decimal</small></label>
          </div>
          <div class="row">
            <div class="form-group">
              <ng-container *ngFor="let separationMethod of getDecimalSeparatorOptions()">
                <button
                  *ngIf="fundamentalsStatementService.decimalSelector.getValue() === separationMethod"
                  class="{{ fundamentalsStatementService.decimalSelector.getValue() === separationMethod ? 'btn-info' : 'btn-light' }} btn btn-sm px-1 mx-auto"
                  (click)="decimalSelectorChanged($event, separationMethod)"
                >
                  <small>
                    {{ separationMethod }}
                  </small>
                </button>
              </ng-container>
            </div>
          </div>
        </div>

        <con-labeling-taxonomy-toggle
          class="col"
          *ngIf="isFundamentalsTaxonomy"
          ngbPopover="Enable this option to display delta values, which checks sanity. It calculates and compares levels, sum of annotations and children modules."
          container="body"
          [openDelay]="300"
          [closeDelay]="0"
          triggers="mouseenter:mouseleave"
          [model]="isDeltaAvailable"
          [name]="'Delta'"
          (toggle)="onDeltaClick()"
          [isDisabled]="isUnavailable"
        ></con-labeling-taxonomy-toggle>

        <con-labeling-taxonomy-button class="col" [name]="'Clear'" [btnClass]="'danger'" [isDisabled]="isUnavailable" [iconName]="'eraser'" (btnClick)="onClearClick()"> </con-labeling-taxonomy-button>
        <con-labeling-taxonomy-button class="col" [name]="'Auto-Fill'" [btnClass]="'info'" [isDisabled]="isUnavailable" [iconName]="'paint-brush'" (btnClick)="autoCompleteStatement()">
        </con-labeling-taxonomy-button>

        <con-labeling-taxonomy-button class="col" [name]="'Reload'" [btnClass]="'warning'" [isDisabled]="isUnavailable" [iconName]="'refresh'" (btnClick)="reloadStatementAnnotations()">
        </con-labeling-taxonomy-button>
      </div>
    </div>

    <div>
      <div class="card-body px-1 pt-1 pb-0">
        <div class="form-inline mb-3 d-flex flex-nowrap justify-content-between"></div>
        <con-entity-search-field
          *ngIf="isFundamentalsTaxonomy"
          [hidden]="isQuantitySelectorHidden && quantity !== null"
          [entityName]="'Quantity'"
          [showDismiss]="false"
          (onSelect)="onQuantitySelect($event)"
        >
        </con-entity-search-field>
      </div>
      <con-taxonomy-fields-list *ngIf="(fundamentalsStatementService.waitingAutoCompleteResponse | async) === false; else loader" [reportStatement]="reportStatement"></con-taxonomy-fields-list>
      <br />
    </div>

    <con-dynamic-clear-data-modal #dynamicClearDataConfirmationModal></con-dynamic-clear-data-modal>

    <ng-template #loader>
      <div class="card col-10">
        <div class="card-body">
          <con-loader></con-loader>
        </div>
      </div>
    </ng-template>
  `,
})
export class LabelingTaxonomyComponent implements OnChanges, OnInit, OnDestroy {
  @Input() reportStatement: ReportStatement;
  @Input() isFundamentalsTaxonomy: boolean;
  @Input() isDeltaAvailable: boolean;
  @Input() instanceJsonAnnotations: Array<FundamentalsAnnotation>;

  private subscribeUntil: Subject<boolean> = new Subject();
  public quantity: Quantity = null;
  public isQuantitySelectorHidden = true;
  public isFullyAnnotated: boolean | null;
  public isUnavailable = true;
  private annotationCleared = false;

  @ViewChild('dynamicClearDataConfirmationModal') dynamicClearDataConfirmationModal: DynamicClearDataModalComponent;
  @ViewChild('isUnavailableToggle') isUnavailableToggle: LabelingTaxonomyToggleComponent;

  constructor(
    public fundamentalsStatementService: FundamentalsStatementService,
    public instanceService: InstanceService,
    public utilService: UtilService,
    public labelingHelperService: LabelingHelperService,
    public docProcessService: DocProcessService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.reportStatement.is_visited = true;
    this.quantity = this.labelingHelperService.getQuantity();
    this.isFullyAnnotated = this.labelingHelperService.getIsFullyAnnotated();
    this.isUnavailable = this.labelingHelperService.getIsUnavailable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.instanceJsonAnnotations?.currentValue) {
      UtilService.logIfAdmin('DocProcessStatementViewComponent ngOnChanges changes.instanceJsonAnnotations');
      UtilService.logIfAdmin(changes?.instanceJsonAnnotations);
      if (!UtilService.areObjectsEqual(changes?.instanceJsonAnnotations?.currentValue, changes?.instanceJsonAnnotations?.previousValue)) {
        this.loadAnnotationsFromBackend();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscribeUntil.next(true);
  }

  public onQuantitySelect($event: Quantity): void {
    this.quantity = $event;
    this.labelingHelperService.setDocumentQuantity($event);
    this.isQuantitySelectorHidden = true;
  }

  public onClearClick(): void {
    this.dynamicClearDataConfirmationModal.show().then(
      (result) => {
        this.clearAllAnnotations();
      },
      (reason) => {
        return;
      }
    );
  }

  public getDecimalSeparatorOptions(): Array<DecimalSeparator> {
    return this.utilService.ToStringArray(DecimalSeparator) as Array<DecimalSeparator>;
  }

  onFullyAnnotatedClick() {
    this.isFullyAnnotated = !this.isFullyAnnotated;
    this.labelingHelperService.setIsFullyAnnotated(this.isFullyAnnotated);
  }

  decimalSelectorChanged($event, separationMethod) {
    const newDecimalSelectorIndex = this.utilService.modulo(this.getDecimalSeparatorOptions().findIndex((a) => a === separationMethod) + 1, this.getDecimalSeparatorOptions().length);
    const newSeperationMethod = this.getDecimalSeparatorOptions().find((a) => a === this.getDecimalSeparatorOptions()[newDecimalSelectorIndex]);
    this.fundamentalsStatementService.decimalSelector.next(newSeperationMethod);
  }

  clearAllAnnotations() {
    this.fundamentalsStatementService.clearStatement();
  }

  public autoCompleteStatement() {
    this.fundamentalsStatementService.autoCompleteStatement(this.reportStatement);
  }

  @HostListener('window:keydown', ['$event'])
  onKeyboardEvent(ev: KeyboardEvent) {
    if (ev.shiftKey && (ev.key === 'a' || ev.key === 'A')) {
      ev.preventDefault();
      ev.stopPropagation();
      this.autoCompleteStatement();
    }
  }

  public reloadStatementAnnotations(): void {
    this.fundamentalsStatementService.reloadStatementAnnotations(this.reportStatement);
    this.annotationCleared = false;
  }

  private loadAnnotationsFromBackend() {
    if (!this.instanceJsonAnnotations) {
      console.error("You shouldn't be calling this function without this.instanceJsonAnnotations are loaded.");
    }

    const initialAnnotations: Array<FundamentalsAnnotation> = this.instanceJsonAnnotations.filter(
      (jsonAnnotation: FundamentalsAnnotation) => jsonAnnotation.fields[0].context?.statementId === this.reportStatement.id
    );

    if (initialAnnotations.length >= 1) {
      this.fundamentalsStatementService.decimalSelector.next(initialAnnotations[0].fields[0].context?.decimalSeparator);
    }

    this.fundamentalsStatementService.initialAnnotationsLoader.next([initialAnnotations, this.reportStatement]);
  }

  onDeltaClick() {
    this.labelingHelperService.toggleIsDeltaDisplayed(this.isDeltaAvailable);
  }

  onUnavailableClick($event) {
    if ($event) {
      this.dynamicClearDataConfirmationModal.show().then(
        (result) => {
          this.fundamentalsStatementService.clearStatement();
          this.isUnavailable = true;
          this.labelingHelperService.setIsUnavailable(this.isUnavailable);
        },
        (reason) => {
          this.isUnavailable = false;
          this.labelingHelperService.setIsUnavailable(this.isUnavailable);
          this.isUnavailableToggle.isChecked = false;
        }
      );
    } else {
      this.isUnavailable = false;
      this.labelingHelperService.setIsUnavailable(this.isUnavailable);
    }
  }
}
