import { Component, Input, OnInit } from '@angular/core';
import { DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { Observable } from 'rxjs';
import { TaskDataEntryState } from '../state-management/states';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { InstanceService } from '../services/instance.service';
import { SetComponentData } from '../state-management/actions';

@Component({
  selector: 'con-dynamic-data-entry-integer-input',
  template: `
    <div class="form-group">
      <input class="form-control" type="number" [ngModel]="value$ | async" (ngModelChange)="updateValue($event)" name="offsetMonth" />
    </div>
  `,
})
export class DynamicDataEntryIntegerInputComponent implements OnInit {
  @Input() component: DynamicDataEntryComponentInterface;
  public value$: Observable<number>;

  constructor(private store: Store, public instanceService: InstanceService) {}

  ngOnInit(): void {
    this.value$ = this.store.select(TaskDataEntryState.selectComponentData(this.instanceService.getInstanceId(), this.component)).pipe(
      map((value: any) => {
        return value as number;
      })
    );
  }

  updateValue($event: any) {
    this.store.dispatch(new SetComponentData(this.instanceService.getInstanceId(), this.component, $event));
  }
}
