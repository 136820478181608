export enum FieldColor {
  Current = '#d1ecf1',
  Validated = '#d4edda',
  Previous = '#fff',
  Low = '#fff7d7',
}

export enum FieldClass {
  Current = 'current',
  Validated = 'validated',
  Previous = 'previous',
  ValidatedPrevious = 'validated-previous',
}
