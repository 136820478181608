import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DocProcessService } from '../../doc-process-common/services/doc-process.service';
import { TaskDataEntryState } from '../state-management/states';
import { Store } from '@ngxs/store';
import { FundamentalsStatementService } from './fundamentals-statement.service';
import { DocumentsLazyLoadingService } from './documents-lazy-loading.service';

@Injectable({
  providedIn: 'root',
})
export class InstanceService {
  public instanceId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public isInstanceSelected: boolean;
  activeFundamentalsStatementService: BehaviorSubject<FundamentalsStatementService> = new BehaviorSubject<FundamentalsStatementService>(null); //TODO: this is bad, to refactor

  constructor(private docProcessService: DocProcessService, private store: Store, private documentsLazyLoading: DocumentsLazyLoadingService) {}

  public getTaskInstanceById(instanceId) {
    return this.store.selectSnapshot(TaskDataEntryState.selectTaskInstanceById(instanceId));
  }

  public getInstanceId(): number {
    return this.instanceId.getValue();
  }

  public getSelectedInstanceId(): number {
    return this.store.selectSnapshot(TaskDataEntryState.selectSelectedInstanceId);
  }

  public setInstanceId(val: number): void {
    this.instanceId.next(val);
    this.isInstanceSelected = this.getSelectedInstanceId() === val;
  }

  public getPrevInstanceId(id: number): number {
    if (this.store.selectSnapshot(TaskDataEntryState.selectPreviousTaskInstance(id))) {
      return this.store.selectSnapshot(TaskDataEntryState.selectPreviousTaskInstance(this.getInstanceId())).instance_id;
    } else return null;
  }

  public loadInstanceDocumentsBatch(id: number) {
    this.documentsLazyLoading.loadNextBatch(id);
  }
}
