import { Component, Input, OnInit } from '@angular/core';
import { TaskDataEntryState } from '../state-management/states';
import { InstanceService } from '../services/instance.service';
import { Store } from '@ngxs/store';
import { ComponentService } from '../services/component.service';
import { DynamicDataEntryAbstractInput } from './dynamic-data-entry-abstract-input.component';
import { Hotkeys } from '../services/hotkeys.service';

@Component({
  selector: 'con-dynamic-single-horizontal-selector',
  template: `
    <con-dynamic-confirmation-icon *ngIf="isConfirmable" [showEmpty]="this.showEmpty" [isConfirmed]="this.isConfirmed"></con-dynamic-confirmation-icon>
    <div class="d-flex w-100 justify-content-start flex-wrap mb-2">
      <button
        *ngFor="let choice of component.enums; let i = index"
        [class]="(componentData.value === choice ? 'btn-primary' : 'btn-disabled') + ' btn-sm mr-1 mb-1 px-2 py-2 cursor-pointer'"
        (click)="onOptionClick($event.target, choice)"
        [value]="choice"
      >
        {{ choice }}
      </button>
    </div>
  `,
  styles: ['button { border: none; } .btn-disabled { background: white } button {min-width: 2rem}'],
})
export class DynamicSingleHorizontalSelectorComponent extends DynamicDataEntryAbstractInput {
  constructor(store: Store, instanceService: InstanceService, componentService: ComponentService, hotkeys: Hotkeys) {
    super(store, instanceService, componentService, hotkeys);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onOptionClick(target: any, choice: string | number) {
    const currentValue = this.store.selectSnapshot(TaskDataEntryState.selectComponentData(this.instanceService.getInstanceId(), this.component));
    if (choice !== currentValue) {
      this.componentValueToStore(choice);
    } else {
      this.componentValueToStore(null);
    }
  }
}
