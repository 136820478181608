import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { Hotkeys } from '../services/hotkeys.service';

@Component({
  selector: 'con-dynamic-data-entry-shareclasses-input',
  template: `<div class="share-class-field">
    <con-dynamic-confirmation-icon class="mr-1 mt-2" [showEmpty]="!inputData?.ml_conf" [isConfirmed]="inputData?.confirmed"></con-dynamic-confirmation-icon>
    <input type="{{ inputType }}" class="form-control smaller-input share-class-input" [placeholder]="component.title" [ngModel]="inputData?.value" (ngModelChange)="updateValue($event)" />
  </div>`,
  styles: [
    `
      .share-class-field {
        min-width: 140px;
      }
      .smaller-input {
        padding: 0 10px;
        height: 30px;
        vertical-align: middle;
      }
      .share-class-input {
        max-width: 120px;
        margin-right: 0;
      }
      con-dynamic-confirmation-icon {
        vert-align: middle;
      }
    `,
  ],
})
export class DynamicShareClassesInputComponent implements OnInit, OnDestroy {
  @Input() component: DynamicDataEntryComponentInterface;
  @Input() inputData: any;
  @Input() isSelected: boolean;
  @Input() isInstanceSelected: boolean;
  @Output() updateEvent = new EventEmitter<any>();
  @Output() confirmationEvent = new EventEmitter<any>();
  public inputType: string;
  private subscription: Subscription;

  constructor(private store: Store, private hotkeys: Hotkeys) {}

  ngOnInit() {
    this.inputType = this.component.component_type === 'numerical-input' ? 'number' : 'text';
    this.subscription = this.hotkeys.addShortcut({ keys: 'control.space' }).subscribe(() => {
      {
        if (this.isSelected && this.isInstanceSelected) {
          this.confirmationEvent.emit();
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateValue($event: any) {
    this.updateEvent.emit($event);
  }
}
