import { DynamicDataEntryComponentInterface } from './dynamic-data-entry-component-interface';
import { ReportStatement } from './report-statement';
import { Annotation } from './annotation';

export interface InstanceData {
  id: number;
  report_type: string;
  report_date: string;
  is_fully_annotated: boolean | null;
  key_financial_only?: boolean | null; //THIS GONNA BE DEPRECATED
  files_completed: boolean;
  company_id: number;
  created_by: number;
  updated_by: number;
  deleted_by: any;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  locked: boolean;
  offset_month: number;
  currency_id: number;
  accounting_standard: any;
  key_financials_approved: boolean;
  taxonomies: [any];
  currency: any;
  company: any;
  components?: DynamicDataEntryComponentInterface[];
  income_statements: Array<ReportStatement>;
  balance_sheets: Array<ReportStatement>;
  cash_flow_statements: Array<ReportStatement>;
  snapshot_kpis: Array<ReportStatement>;
  period_kpis: Array<ReportStatement>;
}

export class InstanceDetails {
  'instance_id': number;
  'instance_data': InstanceData;
  'annotations': Annotation[];
  'components'?: DynamicDataEntryComponentInterface[];
  constructor(instanceId: number, instanceData: InstanceData, annotations: Annotation[]) {
    this.instance_id = instanceId;
    this.instance_data = instanceData;
    this.annotations = annotations;
    this.components = instanceData.components;
  }

  // to create
  public getInstaceDetailsData() {
    return {
      [this.instance_id]: this.instance_data,
    };
  }

  public removeComponents() {
    delete this.components;
    delete this.instance_data.components;
  }
}
