import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'con-labeling-taxonomy-button',
  styles: [
    `
      label {
        margin-left: auto;
        margin-right: auto;
        white-space: nowrap;
      }

      label.switch {
        transform: scale(0.7);
      }
      .disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    `,
  ],
  template: `
    <div class="row" [ngClass]="{ disabled: isDisabled }">
      <label
        ><small>{{ name }}</small></label
      >
    </div>
    <div class="row">
      <button [disabled]="isDisabled" class="d-block btn btn-sm px-1 mx-auto d-block" (click)="onClick()" [ngClass]="['btn-' + btnClass]">
        <con-icon-by-name class="mx-1" iconName="{{ iconName }}"></con-icon-by-name>
      </button>
    </div>
  `,
})
export class LabelingTaxonomyButtonComponent {
  @Input() name: string;
  @Input() isDisabled: boolean;
  @Input() btnClass = 'primary';
  @Input() iconName: string;
  @Output() btnClick = new EventEmitter();

  onClick(): void {
    this.btnClick.emit();
  }
}
