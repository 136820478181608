import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { ComponentConfirmationEnum, DynamicDataEntryComponentInterface } from '../interfaces/dynamic-data-entry-component-interface';
import { DynamicDataEntryComponentTypeEnum } from '../models/dynamic-data-entry-component-type-enum';
import { InstanceService } from '../services/instance.service';
import { Store } from '@ngxs/store';
import { TaskDataEntryState } from '../state-management/states';
import { Subscription } from 'rxjs';
import { ComponentService } from '../services/component.service';
import { map } from 'rxjs/operators';
import { SetComponentData } from '../state-management/actions';
import { Hotkeys } from '../services/hotkeys.service';

@Directive({})
export abstract class DynamicDataEntryAbstractInput implements OnInit, OnDestroy {
  @Input() component: DynamicDataEntryComponentInterface;
  public componentData: any;
  public isConfirmable: boolean;
  public isConfirmed: boolean;
  public showEmpty: boolean;
  private subscription: Subscription;
  private subscription2: Subscription;

  get DynamicDataEntryComponentTypeEnum() {
    return DynamicDataEntryComponentTypeEnum;
  }

  constructor(public store: Store, public instanceService: InstanceService, public componentService: ComponentService, public hotkeys: Hotkeys) {}

  ngOnInit(): void {
    this.isConfirmable = this.component?.confirmation;
    this.subscription = this.store
      .select(TaskDataEntryState.selectComponentData(this.instanceService.getInstanceId(), this.component))
      .pipe(
        map((value: any) => {
          return this.componentService.componentIncomingDataAdapter(value);
        })
      )
      .subscribe((value) => {
        this.componentData = value;
        this.isConfirmed = Boolean(value.confirmed);
        this.isConfirmable = this.component.confirmation;
        this.showEmpty = !value.ml_conf && this.isConfirmed === false;
      });

    this.subscription2 = this.hotkeys.addShortcut({ keys: 'control.space' }).subscribe(() => {
      const isInstanceSelected = this.instanceService.isInstanceSelected;
      if (this.component.isSelected && isInstanceSelected) {
        this.isConfirmed = true;
        this.componentValueToStore(this.componentData.value, 'High', false, ComponentConfirmationEnum.CHECKED);
      }
    });
  }

  componentValueToStore(value: any, ml_conf = 'High', altered = true, confirmed = ComponentConfirmationEnum.ALTERED) {
    const currentStoreData = this.store.selectSnapshot(TaskDataEntryState.selectComponentData(this.instanceService.getInstanceId(), this.component));
    this.store
      .dispatch(new SetComponentData(this.instanceService.getInstanceId(), this.component, this.componentService.componentOutcomingDataAdapter(value, currentStoreData)))
      .subscribe((action) => {
        this.isConfirmed = true;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();
  }
}
