import { Injectable } from '@angular/core';
import { HslColor } from '../models/hsl-color';
import { SignEnum } from '../models/sign-enum';
import * as seedrandom from 'seedrandom';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  private intervalTimer: any; // TODO I should be a stateless service.
  constructor() {}

  isNumeric(str: string) {
    if (typeof str === 'string') {
      return str.split('').some((char) => Number(char) >= 0 || Number(char) <= 0);
    } else {
      return true;
    }
  }

  isDecimal(n: any) {
    const regex = /^[+-]?(\d*\.)?\d+$/g;
    const passedCheck = regex.test(n);
    return passedCheck;
  }

  isValidDate(str: string) {
    if (parseInt(str.split('-')[2]) > 31) {
      return false;
    }
    const regex = /^(\d{4})-(\d{2})-(\d{2}) ?((\d{2}):(\d{2}):(\d{2}))?$/g;
    const passedCheck = regex.test(str);
    return passedCheck;
  }
  getConfirmation(message: string) {
    const hasConfirmed = confirm(message);
    return hasConfirmed === true;
  }
  isJsonString(json: string) {
    const testValue = json.toString();
    try {
      JSON.parse(testValue);
    } catch (e) {
      return false;
    }
    return true;
  }
  getMilliSeconds(num: number) {
    return num * 1000;
  }
  refreshPage(seconds: number, callback: () => void) {
    const milis = this.getMilliSeconds(seconds);
    this.intervalTimer = setInterval(() => callback(), milis);
  }
  stopRefreshingPage() {
    clearInterval(this.intervalTimer);
  }
  debounce(func, wait, immediate) {
    let timeout;
    return function () {
      const context = this,
        args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  }
  flatMap<T, U>(array: T[], mapFunc: (x: T) => U[]): U[] {
    return array.reduce((cumulus: U[], next: T) => [...mapFunc(next), ...cumulus], <U[]>[]);
  }

  prettify(headerName: string) {
    return this.capitalize(headerName.replace(/_/g, ' '));
  }
  capitalize(item: string) {
    if (typeof item !== 'string') {
      return '';
    }
    return item.charAt(0).toUpperCase() + item.slice(1);
  }

  // Helper
  StringIsNumber = (value) => isNaN(Number(value)) === false;

  // Turn enum into array
  ToArray(enumme) {
    return Object.keys(enumme)
      .filter(this.StringIsNumber)
      .map((key) => enumme[key]);
  }

  // Turn enum into array
  ToStringArray(enumme) {
    return Object.keys(enumme).map((key) => enumme[key]);
  }

  hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.5)';
    }
    return '0,0,0,+.2';
  }
  removeEmptyEntries(arr: any[]) {
    return arr.filter((e) => e);
  }

  setZoom(zoom, el) {
    if (!el) return;

    let transformOrigin = [0, 0];
    el = el;
    var p = ['webkit', 'moz', 'ms', 'o'],
      s = 'scale(' + zoom + ')',
      oString = transformOrigin[0] * 100 + '% ' + transformOrigin[1] * 100 + '%';

    for (var i = 0; i < p.length; i++) {
      el.style[p[i] + 'Transform'] = s;
      el.style[p[i] + 'TransformOrigin'] = oString;
    }

    el.style['transform'] = s;
    el.style['transformOrigin'] = oString;
  }
  public zoomScale = 10;
  showVal(a) {
    this.zoomScale = a;
    var zoomScale = Number(this.zoomScale) / 10;

    this.setZoom(zoomScale, document.getElementById('sandbox'));
  }

  public quantityMultiplier(quantity: string): number {
    switch (quantity) {
      case 'B':
        return 10 ** 9;
      case 'M':
        return 10 ** 6;
      case 'T':
        return 10 ** 3;
      case 'r':
        return 10 ** 0;
      case 'c':
        return 10 ** -2;
      default:
        return 1;
    }
  }

  // % operator. i know this feels like a relentless joke,
  // but native modulo in js doesn't work for negative numbers.
  // so that's why we have this function in utils.
  public modulo(dividend: number, divisor: number): number {
    const remainder: number = ((dividend % divisor) + divisor) % divisor;
    return remainder;
  }

  public static modulo(dividend: number, divisor: number): number {
    return new UtilService().modulo(dividend, divisor);
  }

  public static getUpperRouteUrl(pathname: string): string { // TODO what is upper route? upper of what?
    const routeDivisions = pathname.split('/');
    const upperRoute = routeDivisions.slice(0, routeDivisions.length - 1).join('/');
    return upperRoute;
  }

  public static generateGuid() { // TODO maybe move me to the IdentifierModel class?
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
  }

  public static generateRandomColor(seed?: string) {
    let rand;

    if (seed) rand = seedrandom(seed)();
    else rand = Math.random();

    return '#' + (((1 << 24) * rand) | 0).toString(16);
  }

  public static generateRandomLightColor(seedInput?: string): string {
    let seed: string;

    seed = seedInput;
    if (!seed) seed = Math.random().toString();

    const color = seedrandom(seed + '1')() * 360;
    const saturation = seedrandom(seed + '2')() * 70 + 30;
    const light = seedrandom(seed + '3')() * 15 + 80;
    return 'hsl(' + color + ',' + saturation + '%,' + light + '%)';
  }

  public static generateColorsInOrder(start: number, finish: number, count: number): Array<HslColor> {
    let colors: Array<HslColor> = [];

    for (let i = start; i < finish; i = i + (finish - start) / count) colors.push(new HslColor(i, 100, 50));

    return colors;
  }

  public static generateColorTonesInOrder(color: number, lightnessStart: number, lightnessFinish: number, count: number): Array<HslColor> {
    let colors: Array<HslColor> = [];

    for (let i = lightnessStart; i < lightnessFinish; i = i + (lightnessFinish - lightnessStart) / count) colors.push(new HslColor(color, 100, i));

    return colors;
  }

  public static isAdmin(): boolean {
    let adminEmails = [
      'bora.kirca@infrontfinance.com',
      'eivihd.havikbotn@infrontfinance.com',
      'Havikbotn@infrontfinance.com',
      'havikbotn@infrontfinance.com',
      'viktor.karlsson@inquiry.se',
      'mehdi.benlazreg@infrontfinance.com',
      'andre.fagereng@infrontfinance.com',
      'marcus.engstroem@infrontfinance.com',
      'johan.bjork@infrontfinance.com',
      'michal.szela.ext@infrontfinance.com',
      'askorska@sii.pl',
      'dominik.wiecek.ext@infrontfinance.com'
    ];
    let userEmail = JSON.parse(localStorage.getItem('USER-DETAILS')).email;
    if (adminEmails.findIndex((val) => val.toLowerCase() === userEmail.toLowerCase()) > -1) return true;
    else return false;
  }

  public static logIfAdmin(logContents: any): void {
    if (UtilService.isAdmin()) {
      // console.log(logContents);
    }
  }

  public static areArraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  public static getTextLengthOfDomObject(childNode: ChildNode): number {
    if (!!(childNode as any)?.innerText) return (childNode as any).innerText.length;
    if (!!(childNode as any)?.wholeText) return (childNode as any).wholeText.length;
    return 0;
  }

  public static detectSign(str: string): SignEnum {
    const negativeNumberIndicators: Array<string> = ['-', '(', '–', '-', '–', '—', '‐', '−', '‒', '‑', '─', '⎯', '▬', '₋', '－', '¯', '­', 'ꟷ', 'ー'];
    for (let eachChar of str) {
      if (!!negativeNumberIndicators.find((minusChar) => minusChar === eachChar)) return SignEnum.Negative;
      if (new RegExp(/\d+/).test(eachChar)) return SignEnum.Positive;
    }
    return SignEnum.Positive;
  }

  public static deepFreeze<T>(inObj: T): T {
    Object.freeze(inObj);

    Object.getOwnPropertyNames(inObj).forEach(function (prop) {
      if (inObj.hasOwnProperty(prop) && inObj[prop] != null && typeof inObj[prop] === 'object' && !Object.isFrozen(inObj[prop])) {
        UtilService.deepFreeze(inObj[prop]);
      }
    });
    return inObj;
  }

  // returns true if two objects are equal.
  public static areObjectsEqual(objOne, objTwo): boolean {
    return JSON.stringify(objOne) === JSON.stringify(objTwo);
  }

  public static copyObject(objOne): any {
    return JSON.parse(JSON.stringify(objOne));
  }

  public static removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {
      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);

      //reverse iteration as may be destructive
      for (var i = pars.length; i-- > 0; ) {
        //idiom for string.startsWith
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
  }

  public static getRangeFromHtmlElement(htmlElement: HTMLElement): Range {
    try {
      let textNode = htmlElement.firstChild;
      let range = document.createRange();
      range.setStart(textNode, 0);
      range.setEnd(textNode, textNode.textContent.length);
      return range;
    } catch (error) {
      return null;
    }

  }

  static languageToFlag(language) {
    if (language === 'en') return 'gb';
    if (language === 'sv') return 'se';
    if (language === 'da') return 'dk';

    return language;
  }
}
